<template>
  <div class="UploaderManageUsersPage">
    <loading-box v-if="providers === null || allUsers === null"
      >Loading...</loading-box
    >
    <div v-else class="main">
      <div class="providerList">
        <div class="provider" v-for="providerId in providers" :key="providerId">
          <div class="heading">
            <h2>{{ providerId }}</h2>
            <button v-on:click="() => expandProvider(providerId)">
              MANAGE USERS
            </button>
          </div>
          <template v-if="selectedProvider == providerId">
            <div class="users">
              <loading-box v-if="providerUsers === null"
                >Loading users...</loading-box
              >
              <template v-else>
                <div v-for="user in providerUsers" :key="user" class="userRow">
                  <a v-on:click="removeUserFromProvider(user, providerId)"
                    ><img src="../../assets/remove.png"
                  /></a>
                  <span>{{ user }}</span>
                </div>
                <div v-if="providerUsers.length == 0">
                  There are no users associated to this provider.
                </div>
                <div class="addUser">
                  <select v-model="newAddUserToProviderName">
                    <option
                      v-for="user in allUsers.filter(
                        (t) =>
                          !(
                            providerUsers.map((s) => s.toLowerCase()) ?? []
                          ).includes(t.toLowerCase())
                      )"
                      :key="user"
                    >
                      {{ user }}
                    </option>
                  </select>
                  <button
                    v-if="!isUserBeingAddedToProvider"
                    v-on:click="
                      () =>
                        addUserToProvider(newAddUserToProviderName, providerId)
                    "
                  >
                    ADD USER
                  </button>
                  <button v-if="isUserBeingAddedToProvider" disabled>
                    ADDING...
                  </button>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="statusContainer">
      <message-box
        class="messageBox"
        v-if="status?.ok === false"
        type="error"
        >{{ status.message }}</message-box
      >
      <message-box
        class="messageBox"
        v-if="status?.ok === true"
        type="success"
        >{{ status.message }}</message-box
      >
    </div>
  </div>
</template>

<script>
import LoadingBox from "../../components/LoadingBox.vue";
import MessageBox from "../../components/MessageBox.vue";

export default {
  components: { LoadingBox, MessageBox },
  name: "UploaderManageUsersPage",
  inject: ["router", "store"],
  props: {
    route: Object,
  },
  data: function () {
    return {
      status: null,
      apiBaseUrl: null,
      providers: null,
      activeProvider: null,
      providerUsers: null,
      selectedProvider: null,
      allUsers: null,
      newAddUserToProviderName: "",
      isUserBeingAddedToProvider: false,
    };
  },
  created: async function () {
    if (!this.route.payload?.apiBaseUrl) {
      console.error(
        `Cannot start file uploader. The attribute "apiBaseUrl" was not set in the payload for "${this.route.name}".`
      );
      return;
    }
    this.apiBaseUrl = this.route.payload.apiBaseUrl;
    await this.loadProviders();
    await this.loadAllUsers();
  },
  methods: {
    loadProviders: async function () {
      const res = await fetch(`${this.apiBaseUrl}/allProviders`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${await this.store.refreshAndGetAuthToken()}`,
        },
      });
      const resBody = await res.json();
      this.providers = resBody.providers;
    },
    loadAllUsers: async function () {
      const res = await fetch(`${this.apiBaseUrl}/allUsers`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${await this.store.refreshAndGetAuthToken()}`,
        },
      });
      const resBody = await res.json();
      this.allUsers = resBody.users;
    },
    expandProvider: async function (providerId) {
      this.selectedProvider = providerId;
      this.newAddUserToProviderName = "";
      this.providerUsers = null;
      const res = await fetch(
        `${this.apiBaseUrl}/allUsersInProvider/${providerId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${await this.store.refreshAndGetAuthToken()}`,
          },
        }
      );
      const resBody = await res.json();
      this.providerUsers = resBody.users;
    },
    removeUserFromProvider: async function (username, providerId) {
      const confirmation = confirm(
        `Are you sure you want to remove user ${username} from provider ${providerId}?`
      );
      if (!confirmation) {
        return;
      }

      const res = await fetch(
        `${this.apiBaseUrl}/removeUserFromProvider/${providerId}/${username}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${await this.store.refreshAndGetAuthToken()}`,
          },
        }
      );
      await res.json();
      await this.expandProvider(providerId);
    },
    addUserToProvider: async function (username, providerId) {
      this.isUserBeingAddedToProvider = true;
      const res = await fetch(`${this.apiBaseUrl}/addUserToProvider`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${await this.store.refreshAndGetAuthToken()}`,
        },
        body: JSON.stringify({
          providerId,
          username,
        }),
      });
      await res.json();
      await this.expandProvider(providerId);
      this.isUserBeingAddedToProvider = false;
    },
  },
};
</script>

<style scoped>
.UploaderManageUsersPage {
  padding: 24px;
  font-size: 0.7rem;
}

.provider:not(:first-child) {
  margin-top: 24px;
}

.provider .heading {
  display: flex;
  align-items: center;
  gap: 16px;
}

.provider .heading h2 {
  flex: 1 0 auto;
}

.provider .users {
  border-radius: 16px;
  margin-top: 16px;
  background-color: var(--subtleSeparator);
  padding: 16px;
}

.provider .users .addUser {
  display: flex;
  gap: 16px;
  border-top: 1px solid var(--subtleSeparator);
  padding-top: 16px;
  margin-top: 16px;
}

.provider .users .userRow:not(:first-child) {
  margin-top: 8px;
}
.provider .users .userRow {
  display: flex;
  align-items: center;
  gap: 8px;
}

.provider .users img {
  width: 9px;
  cursor: pointer;
}

.statusContainer {
  margin-top: 16px;
}
</style>
