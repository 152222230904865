<template>
  <div class="PageViewer">
    <div class="mainArea">
      <div v-for="(entry, key) in cachedPaths" :key="key" v-show="path == key">
        <folder-page
          v-if="entry.route.type == 'folder'"
          :path="key"
        ></folder-page>
        <embed-page
          v-if="entry.route.type == 'embed'"
          :route="entry.route"
        ></embed-page>
        <form-page
          v-if="entry.route.type == 'form'"
          :route="entry.route"
        ></form-page>
        <uploader-page
          v-if="entry.route.type == 'uploader'"
          :route="entry.route"
        ></uploader-page>
        <uploader-jobs-page
          v-if="entry.route.type == 'uploaderJobs'"
          :route="entry.route"
        ></uploader-jobs-page>
        <uploader-create-page
          v-if="entry.route.type == 'uploaderCreate'"
          :route="entry.route"
        ></uploader-create-page>
        <uploader-tables
          v-if="entry.route.type == 'uploaderTables'"
          :route="entry.route"
        ></uploader-tables>
        <uploader-manage-providers
          v-if="entry.route.type == 'uploaderManageProviders'"
          :route="entry.route"
        ></uploader-manage-providers>
        <uploader-manage-users
          v-if="entry.route.type == 'uploaderManageUsers'"
          :route="entry.route"
        ></uploader-manage-users>
      </div>
    </div>
  </div>
</template>

<script>
import FolderPage from "../pages/FolderPage.vue";
import EmbedPage from "../pages/EmbedPage.vue";
import FormPage from "../pages/FormPage.vue";
import UploaderPage from "../pages/uploader/UploaderPage.vue";
import UploaderJobsPage from "../pages/uploader/UploaderJobsPage.vue";
import UploaderCreatePage from "../pages/uploader/UploaderCreatePage.vue";
import UploaderTables from "../pages/uploader/UploaderTables.vue";
import UploaderManageProviders from "../pages/uploader/UploaderManageProviders.vue";
import UploaderManageUsers from "../pages/uploader/UploaderManageUsers.vue";

export default {
  name: "PageViewer",
  components: {
    FolderPage,
    EmbedPage,
    FormPage,
    UploaderPage,
    UploaderJobsPage,
    UploaderCreatePage,
    UploaderTables,
    UploaderManageProviders,
    UploaderManageUsers,
  },
  inject: ["routes", "router"],
  emits: ["sideLoad"],
  props: {
    path: String,
    sideVisible: Boolean,
  },
  data: function () {
    return {
      pageVersion: 1,
      cachedPaths: {},
    };
  },
  methods: {
    matchRoute: function (toPath) {
      return this.router.methods.matchRoute(toPath);
    },
  },
  watch: {
    path: {
      handler: function (newPath) {
        const newCachedPaths = {};
        const newAnchorPath = this.router.methods.getAnchorPath(newPath);

        this.routes.forEach((route) => {
          if (route.noCache == "true" && route.path != newPath) return;
          const routeAnchorPath = this.router.methods.getAnchorPath(route.path);
          if (routeAnchorPath != newAnchorPath) return;
          newCachedPaths[route.path] = { route: route };
        });

        this.cachedPaths = newCachedPaths;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.PageViewer {
  display: flex;
  flex: 1 1 100%;
  height: 100%;
  flex-wrap: wrap;
}

.sideArea {
  flex: 0 0 max(400px, 20%);
  padding: 16px;
}

.sideArea .widgetContainer:not(:first-child) {
  margin-top: 16px;
}

.mainArea {
  flex: 1 0 60%;
  position: relative;
}

.mainArea > * {
  height: 100%;
  width: 100%;
}

@media (max-width: 500px) {
  .sideArea {
    flex: 0 0 100%;
  }
}
</style>
