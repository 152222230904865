<template>
  <div class="UploaderManageProvidersPage">
    <loading-box v-if="providers === null || allTables === null"
      >Loading...</loading-box
    >
    <div v-else class="main">
      <div class="providerList">
        <div class="provider" v-for="providerId in providers" :key="providerId">
          <div class="heading">
            <h2>{{ providerId }}</h2>
            <button v-on:click="() => expandProvider(providerId)">
              MANAGE TABLES
            </button>
            <button v-on:click="() => deleteProvider(providerId)">
              DELETE PROVIDER
            </button>
          </div>
          <template v-if="selectedProvider == providerId">
            <div class="tables">
              <loading-box v-if="providerTables === null"
                >Loading tables...</loading-box
              >
              <template v-else>
                <div
                  v-for="table in providerTables"
                  :key="table"
                  class="tableRow"
                >
                  <a v-on:click="removeTableFromProvider(table, providerId)"
                    ><img src="../../assets/remove.png"
                  /></a>
                  <span>{{ table }}</span>
                </div>
                <div v-if="providerTables.length == 0">
                  There are no tables associated to this provider.
                </div>
                <div class="addTable">
                  <select v-model="newAddTableToProviderName">
                    <option
                      v-for="table in allTables.filter(
                        (t) => !(providerTables ?? []).includes(t)
                      )"
                      :key="table"
                    >
                      {{ table }}
                    </option>
                  </select>
                  <button
                    v-if="!isTableBeingAddedToProvider"
                    v-on:click="
                      () =>
                        addTableToProvider(
                          newAddTableToProviderName,
                          providerId
                        )
                    "
                  >
                    ADD TABLE
                  </button>
                  <button v-if="isTableBeingAddedToProvider" disabled>
                    ADDING...
                  </button>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
      <div class="addProvider">
        <h2>Add a new provider</h2>
        <div class="form">
          <input
            v-model="newProviderName"
            type="text"
            placeholder="Provider id..."
          />
          <button
            v-if="!isProviderBeingCreated"
            v-on:click="() => addNewProvider()"
          >
            ADD
          </button>
          <button v-if="isProviderBeingCreated" disabled>ADDING...</button>
        </div>
      </div>
    </div>
    <div class="statusContainer">
      <message-box
        class="messageBox"
        v-if="status?.ok === false"
        type="error"
        >{{ status.message }}</message-box
      >
      <message-box
        class="messageBox"
        v-if="status?.ok === true"
        type="success"
        >{{ status.message }}</message-box
      >
    </div>
  </div>
</template>

<script>
import LoadingBox from "../../components/LoadingBox.vue";
import MessageBox from "../../components/MessageBox.vue";

export default {
  components: { LoadingBox, MessageBox },
  name: "UploaderManageProvidersPage",
  inject: ["router", "store"],
  props: {
    route: Object,
  },
  data: function () {
    return {
      status: null,
      apiBaseUrl: null,
      providers: null,
      activeProvider: null,
      providerTables: null,
      selectedProvider: null,
      allTables: null,
      newProviderName: "",
      newAddTableToProviderName: "",
      isTableBeingAddedToProvider: false,
      isProviderBeingCreated: false,
    };
  },
  created: async function () {
    if (!this.route.payload?.apiBaseUrl) {
      console.error(
        `Cannot start file uploader. The attribute "apiBaseUrl" was not set in the payload for "${this.route.name}".`
      );
      return;
    }
    this.apiBaseUrl = this.route.payload.apiBaseUrl;
    await this.loadProviders();
    await this.loadAllTables();
  },
  methods: {
    loadProviders: async function () {
      const res = await fetch(`${this.apiBaseUrl}/allProviders`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${await this.store.refreshAndGetAuthToken()}`,
        },
      });
      const resBody = await res.json();
      this.providers = resBody.providers;
    },
    loadAllTables: async function () {
      const res = await fetch(`${this.apiBaseUrl}/allTables`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${await this.store.refreshAndGetAuthToken()}`,
        },
      });
      const resBody = await res.json();
      this.allTables = resBody.tables;
    },
    expandProvider: async function (providerId) {
      this.selectedProvider = providerId;
      this.newAddTableToProviderName = "";
      this.providerTables = null;
      const res = await fetch(
        `${this.apiBaseUrl}/allTablesInProvider/${providerId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${await this.store.refreshAndGetAuthToken()}`,
          },
        }
      );
      const resBody = await res.json();
      this.providerTables = resBody.tables;
    },
    addNewProvider: async function () {
      const providerId = this.newProviderName;
      if (!providerId) {
        alert("The new provider name must not be empty.");
        return;
      }
      this.isProviderBeingCreated = true;
      const res = await fetch(`${this.apiBaseUrl}/createProvider`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${await this.store.refreshAndGetAuthToken()}`,
        },
        body: JSON.stringify({
          providerId,
        }),
      });
      const resBody = await res.json();
      await this.loadProviders();
      this.isProviderBeingCreated = false;
      this.newProviderName = "";
      if (!res.ok) {
        this.status = {
          ok: false,
          message: resBody.message,
        };
        return;
      }

      this.status = {
        ok: true,
        message: resBody.message,
      };
    },
    deleteProvider: async function (providerId) {
      const confirmation = confirm(
        `Are you sure you want to delete provider ${providerId}?`
      );
      if (!confirmation) {
        return;
      }

      if (this.selectedProvider == providerId) {
        this.selectedProvider = null;
      }
      const res = await fetch(
        `${this.apiBaseUrl}/deleteProvider/${providerId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${await this.store.refreshAndGetAuthToken()}`,
          },
        }
      );
      await res.json();
      await this.loadProviders();
    },
    removeTableFromProvider: async function (tableName, providerId) {
      const confirmation = confirm(
        `Are you sure you want to remove table ${tableName} from provider ${providerId}?`
      );
      if (!confirmation) {
        return;
      }

      const res = await fetch(
        `${this.apiBaseUrl}/removeTableFromProvider/${providerId}/${tableName}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${await this.store.refreshAndGetAuthToken()}`,
          },
        }
      );
      await res.json();
      await this.expandProvider(providerId);
    },
    addTableToProvider: async function (tableName, providerId) {
      this.isTableBeingAddedToProvider = true;
      const res = await fetch(`${this.apiBaseUrl}/addTableToProvider`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${await this.store.refreshAndGetAuthToken()}`,
        },
        body: JSON.stringify({
          providerId,
          tableName,
        }),
      });
      await res.json();
      await this.expandProvider(providerId);
      this.isTableBeingAddedToProvider = false;
    },
  },
};
</script>

<style scoped>
.UploaderManageProvidersPage {
  padding: 24px;
  font-size: 0.7rem;
}

.provider:not(:first-child) {
  margin-top: 24px;
}

.provider .heading {
  display: flex;
  align-items: center;
  gap: 16px;
}

.provider .heading h2 {
  flex: 1 0 auto;
}

.provider .tables {
  border-radius: 16px;
  margin-top: 16px;
  background-color: var(--subtleSeparator);
  padding: 16px;
}

.provider .tables .addTable {
  display: flex;
  gap: 16px;
  border-top: 1px solid var(--subtleSeparator);
  padding-top: 16px;
  margin-top: 16px;
}

.provider .tables .tableRow:not(:first-child) {
  margin-top: 8px;
}
.provider .tables .tableRow {
  display: flex;
  align-items: center;
  gap: 8px;
}

.provider .tables img {
  width: 9px;
  cursor: pointer;
}

.addProvider {
  border-top: 1px solid var(--subtleSeparator);
  padding-top: 24px;
  margin-top: 24px;
}

.addProvider .form {
  margin-top: 16px;
  display: flex;
  gap: 16px;
}

.statusContainer {
  margin-top: 16px;
}
</style>
