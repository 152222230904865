<template>
    <div class="PageFilters" v-if="filters">
        <div class="box">
            <h3>FILTERS</h3>

            <div class="fieldContainer">
                <div class="field" v-for="(element, key) in elements" v-bind:key="key">
                    <span class="desc">{{ element.desc }}</span>
                    <div class="controlContainer">
                        <select v-if="element.type == 'select'">
                            <option v-for="(option, optionKey) in element.options" v-bind:key="optionKey">
                                {{ option.desc ? option.desc : option.id }}
                            </option>
                        </select>
                        <input v-if="element.type == 'date'" type="date" />
                        <div v-if="element.type == 'checkboxes'" class="itemContainer">
                            <div v-for="(option, optionKey) in element.options" v-bind:key="optionKey">
                                <input type="checkbox" /> {{ option.desc ? option.desc : option.id }}
                            </div>
                        </div>
                    </div>
                </div>                
            </div>


            <div class="actions">
                <button v-on:click="alert('To do')">APPLY</button>                
            </div>

        </div>

    </div>
</template>

<script>
    export default {
        name: "PageFilters",
        inject: ["router", "store"],
        computed: {
            filters: function () {
                return this.router.state.activeRoute.filters;
            },
            elements: function () {
                return this.filters.elements;
            }
        }
    }
</script>

<style scoped>

    .PageFilters {
        overflow: hidden;
        padding: 12px;
    }

    h3 {
        margin-bottom: 8px;
    }

    .box {
        padding: 16px;
        background: var(--bg);
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    }


    .fieldContainer {
        margin-top: 16px;
        border-top: 1px solid var(--separator);
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .field {
        flex: 0 1 100%;
        z-index: 1;
        position: relative;
        margin-top: 16px;
    }

    .field > .desc {
        position: relative;
        font-size: 0.7rem;
        background: var(--bg);
        z-index: 2;
        padding: 0 3px 3px 3px;
        left: 5px;
    }

    .field > .message {
        margin-top: 8px;
    }

    .field > .comment {
        font-size: 0.7rem;
        margin-top: 8px;
        color: rgba(0, 0, 0, 0.5);
    }

    .controlContainer {
        margin-top: -10px;
    }

    .controlContainer input[type=text], .controlContainer input[type=date] {
        width: 100%;
        font-size: 0.7rem;
        padding: 12px 8px 8px 8px;
    }
    .controlContainer select {
        width: 100%;
        font-size: 0.7rem;
        padding: 12px 3px 8px 3px;
    }
    .controlContainer .itemContainer {
        width: 100%;
        border: 1px solid var(--normalBorder);
        border-radius: 4px;
        font-size: 0.7rem;
        padding: 12px;
    }

    .controlContainer .itemContainer div {
        display: flex;
        align-items: center;
    }

    .controlContainer .itemContainer div>*:first-child {
        margin-right: 8px;
    }
    .actions {
        margin-top: 16px;
        border-top: 1px solid var(--separator);
        padding-top: 16px;
    }

    button:not(:first-of-type) {
        margin-left: 12px;
    }

</style>
