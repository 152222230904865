<template>
  <div class="UploaderCreatePage">
    <div class="loading" v-if="loading">
      <loading-box>Loading...</loading-box>
    </div>
    <template v-if="!loading">
      <div class="section half">
        <h2>Table Name</h2>
        <div class="summary">This field is required.</div>
        <div class="main">
          <input
            type="text"
            placeholder="Enter the table name..."
            v-model="tableName"
            maxlength="128"
          />
        </div>
      </div>

      <div class="section half">
        <h2>File Name Contains</h2>
        <div class="summary">
          Uploaded files will only be accepted if their names contain the value
          specified. If left blank, no naming convention will be enforced.
        </div>
        <div class="main">
          <input
            type="text"
            placeholder="Enter the file name convention..."
            v-model="fileNamePrefix"
            maxlength="64"
          />
        </div>
      </div>

      <div class="section options">
        <h2>Load Mode</h2>
        <div class="options">
          <div class="option">
            <input type="radio" v-model="loadMode" :value="1" />
            <div class="content">
              <h4>Incremental</h4>
              Data from every upload accumulates in the table.
            </div>
          </div>
          <div class="option">
            <input type="radio" v-model="loadMode" :value="2" />
            <div class="content">
              <h4>Full Reload</h4>
              The target table is cleared every time new data is uploaded.
            </div>
          </div>
        </div>
      </div>

      <div class="section options">
        <h2>Duplicate Handling</h2>
        <div class="options">
          <div class="option">
            <input type="radio" v-model="ifExists" :value="1" />
            <div class="content">
              <h4>Append</h4>
              The existing data is kept. The new file is uploaded.
            </div>
          </div>
          <div class="option">
            <input type="radio" v-model="ifExists" :value="2" />
            <div class="content">
              <h4>Replace</h4>
              The existing data is rolled back. The new file is uploaded.
            </div>
          </div>
          <div class="option">
            <input type="radio" v-model="ifExists" :value="3" />
            <div class="content">
              <h4>Reject</h4>
              The existing data is kept. The new file is rejected.
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <h2>Fields</h2>
        <div class="summary">
          The new table must contain at least one field. Audit fields will be
          added automatically.
        </div>
        <div class="main">
          <div v-if="fields.length == 0">
            No fields have been configured for this table.
          </div>
          <table v-else>
            <tr>
              <th>Column Name</th>
              <th>Presentation Alias</th>
              <th>Data Type</th>
              <th>Data Format</th>
              <th>Is Nullable</th>
              <th>Conversion Fail Severity</th>
              <th>Nullable Fail Severity</th>
              <th></th>
            </tr>
            <template v-for="(field, fieldIndex) in fields" :key="fieldIndex">
              <tr>
                <td>
                  <input
                    type="text"
                    placeholder="Column name..."
                    v-model="field.column_name"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="Alias..."
                    v-model="field.alias"
                  />
                </td>
                <td>
                  <select
                    v-model="field.data_type"
                    style="text-transform: uppercase"
                  >
                    <option
                      v-for="dataType in dataTypes"
                      :key="dataType.data_type_id"
                      :value="dataType"
                    >
                      {{ dataType.desc }}
                    </option>
                  </select>
                </td>
                <td>
                  <select
                    v-if="
                      dataFormats.filter(
                        (e) => e.type_name == field.data_type.type_name
                      ).length > 0
                    "
                    v-model="field.data_format_id"
                  >
                    <option
                      v-for="dataFormat in dataFormats.filter(
                        (e) => e.type_name == field.data_type.type_name
                      )"
                      :key="dataFormat.data_format_id"
                      :value="dataFormat.data_format_id"
                    >
                      {{ dataFormat.name }}
                    </option>
                  </select>
                  <template v-else> N/A </template>
                </td>
                <td>
                  <select v-model="field.is_nullable">
                    <option value="1">Yes (NULL)</option>
                    <option value="0">No (NOT NULL)</option>
                  </select>
                </td>
                <td>
                  <select v-model="field.conversion_fail_severity">
                    <option value="1">Warning</option>
                    <option value="2">Row Failure</option>
                    <option value="3">Error</option>
                  </select>
                </td>
                <td>
                  <select
                    v-if="field.is_nullable == '0'"
                    v-model="field.nullable_fail_severity"
                  >
                    <option value="1">Warning</option>
                    <option value="2">Row Failure</option>
                    <option value="3">Error</option>
                  </select>
                  <template v-else> N/A </template>
                </td>
                <td class="iconContainer">
                  <img
                    src="../../assets/delete.png"
                    v-on:click="() => fields.splice(fieldIndex, 1)"
                  />
                </td>
              </tr>
              <tr
                v-if="
                  status?.fieldErrors?.filter(
                    (e) => e.field_index == fieldIndex
                  ).length > 0
                "
              >
                <td colspan="4" class="error">
                  {{
                    status.fieldErrors
                      .filter((e) => e.field_index == fieldIndex)
                      .map((e) => e.message)
                      .join(" ")
                  }}
                </td>
              </tr>
            </template>
          </table>
        </div>
        <button class="secondary" v-on:click="addField">Add new</button>
      </div>

      <div class="section">
        <h2>Business Rules</h2>
        <div class="summary">
          Validation rules to run whenever new data is uploaded. Optional.
        </div>
        <div class="main">
          <div v-if="businessRules.length == 0">
            No business rules have been configured for this table.
          </div>
          <table v-else>
            <tr>
              <th>Description</th>
              <th>SQL Expression</th>
              <th>Severity</th>
              <th></th>
            </tr>
            <template
              v-for="(rule, ruleIndex) in businessRules"
              :key="ruleIndex"
            >
              <tr>
                <td>
                  <input
                    type="text"
                    placeholder="Description..."
                    v-model="rule.description"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="SQL Expression..."
                    v-model="rule.expr"
                  />
                </td>
                <td>
                  <select v-model="rule.severity">
                    <option value="1">Warning</option>
                    <option value="2">Row Failure</option>
                    <option value="3">Error</option>
                  </select>
                </td>
                <td class="iconContainer">
                  <img
                    src="../../assets/delete.png"
                    v-on:click="() => businessRules.splice(ruleIndex, 1)"
                  />
                </td>
              </tr>
              <tr
                v-if="
                  status?.businessRulesErrors?.filter(
                    (e) => e.rule_index == ruleIndex
                  ).length > 0
                "
              >
                <td colspan="4" class="error">
                  {{
                    status.businessRulesErrors
                      .filter((e) => e.rule_index == ruleIndex)
                      .map((e) => e.message)
                      .join(" ")
                  }}
                </td>
              </tr>
            </template>
          </table>
        </div>
        <button class="secondary" v-on:click="addBusinessRule">Add new</button>
      </div>

      <div class="section">
        <h2>Initial Provider</h2>
        <div class="summary">Determine an initial provider for this table.</div>
        <div class="main">
          <select v-model="initialProvider">
            <option
              v-for="providerId in providers"
              :key="providerId"
              :value="providerId"
            >
              {{ providerId }}
            </option>
          </select>
        </div>
      </div>

      <div class="section actions">
        <message-box
          class="messageBox"
          v-if="status?.ok === false"
          type="error"
          >{{ status.message }}</message-box
        >
        <message-box
          class="messageBox"
          v-if="status?.ok === true"
          type="success"
          >{{ status.message }}</message-box
        >
        <button v-on:click="submit">Create target table</button>
      </div>
    </template>
  </div>
</template>

<script>
import LoadingBox from "../../components/LoadingBox.vue";
import MessageBox from "../../components/MessageBox.vue";

export default {
  name: "UploaderCreatePage",
  components: { LoadingBox, MessageBox },
  inject: ["router", "store"],
  props: {
    route: Object,
  },
  data: function () {
    return {
      loading: true,
      status: null,
      tableName: "",
      fileNamePrefix: "",
      ifExists: 1,
      loadMode: 1,
      initialProvider: null,
      fields: [],
      businessRules: [],
      dataTypes: [],
      dataFormats: [],
      providers: [],
      apiBaseUrl: null,
    };
  },
  created: function () {
    if (!this.route.payload?.apiBaseUrl) {
      console.error(
        `Cannot start file uploader. The attribute "apiBaseUrl" was not set in the payload for "${this.route.name}".`
      );
      return;
    }
    this.apiBaseUrl = this.route.payload.apiBaseUrl;

    Promise.all([
      this.loadDataTypesList(),
      this.loadDataFormatsList(),
      this.loadProvidersList(),
    ]).then(() => {
      this.addField();
      this.loading = false;
    });
  },
  unmounted: function () {
    clearTimeout(this.refreshTimer);
  },
  watch: {
    fields: {
      deep: true,
      handler: function () {
        this.fields.forEach((field) => {
          const relevantDataFormats = this.dataFormats.filter(
            (e) => e.type_name == field.data_type.type_name
          );
          if (relevantDataFormats.length == 0) {
            field.data_format_id = null;
            return;
          }

          const match = relevantDataFormats.filter(
            (e) => e.data_format_id == field.data_format_id
          );
          if (match.length > 0) {
            return;
          }

          field.data_format_id = relevantDataFormats[0].data_format_id;
        });
      },
    },
  },
  methods: {
    loadDataTypesList: async function () {
      const url = `${this.apiBaseUrl}/dataTypes`;
      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${await this.store.refreshAndGetAuthToken()}`,
        },
      });
      const dataTypes = await res.json();

      dataTypes.map((dataType) => {
        dataType.desc = dataType.type_name;
        const attributes = [
          dataType.precision,
          dataType.scale,
          dataType.max_length,
        ]
          .filter((attribute) => attribute)
          .map((e) => (e == -1 ? "max" : e));
        if (attributes.length > 0) {
          dataType.desc += `(${attributes.join(", ")})`;
        }
      });

      this.dataTypes = dataTypes;
    },
    loadDataFormatsList: async function () {
      const url = `${this.apiBaseUrl}/dataFormats`;
      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${await this.store.refreshAndGetAuthToken()}`,
        },
      });
      const dataFormats = await res.json();
      this.dataFormats = dataFormats;
    },
    loadProvidersList: async function () {
      const url = `${this.apiBaseUrl}/providers`;
      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${await this.store.refreshAndGetAuthToken()}`,
        },
      });
      const resBody = await res.json();
      this.providers = resBody.providers;
      this.initialProvider = resBody.providers?.[0];
    },
    addField: function () {
      const defaultDataType = this.dataTypes[0];
      const relevantDataFormats = this.dataFormats.filter(
        (e) => e.type_name == defaultDataType.type_name
      );
      const defaultDataFormat =
        relevantDataFormats.length > 0
          ? relevantDataFormats[0].data_format_id
          : null;
      this.fields.push({
        data_type: defaultDataType,
        data_format_id: defaultDataFormat,
        is_nullable: "1",
        conversion_fail_severity: "1",
        nullable_fail_severity: "3",
      });
    },
    addBusinessRule: function () {
      this.businessRules.push({ severity: 1 });
    },
    submit: async function () {
      const body = {
        tableName: this.tableName,
        fileNamePrefix: this.fileNamePrefix,
        ifExists: this.ifExists,
        loadMode: this.loadMode,
        fields: this.fields,
        businessRules: this.businessRules,
        initialProvider: this.initialProvider,
      };

      const res = await fetch(`${this.apiBaseUrl}/createTargetTable`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${await this.store.refreshAndGetAuthToken()}`,
        },
        body: JSON.stringify(body),
      });
      const resBody = await res.json();

      if (!res.ok) {
        this.status = {
          ok: false,
          message: resBody.message,
          fieldErrors: resBody.fieldErrors,
          businessRulesErrors: resBody.businessRulesErrors,
        };
        return;
      }

      this.status = {
        ok: true,
        message: resBody.message,
      };
    },
  },
};
</script>

<style scoped>
.UploaderCreatePage {
  font-size: 0.7rem;
  background: white;
  display: flex;
  flex-wrap: wrap;
}

.loading {
  padding: 24px;
}

.section {
  padding: 24px;
  width: 100%;
}

.section.half {
  width: 50%;
}

.section.half + .section.half {
  border-left: 1px solid var(--subtleSeparator);
}

.section {
  border-bottom: 1px solid var(--subtleSeparator);
}

.section .summary {
  margin-top: 8px;
}

.section .main {
  margin-top: 8px;
}

.section.options .options {
  display: flex;
  margin-top: 16px;
}

.option {
  background: var(--subtleSeparator);
  padding: 16px;
  border-radius: 4px;
  display: flex;
  max-width: 30ch;
  align-items: center;
}

.option:not(:first-child) {
  margin-left: 8px;
}

.option input {
  flex: 0 1 12px;
  margin-right: 16px;
}

table {
  margin-left: -4px;
  margin-right: -4px;
  max-width: 240ch;
}

tr {
}

th,
td {
  padding: 4px;
  width: 30ch;
  max-width: 40ch;
}

td.error {
  padding: 0 4px 8px 4px;
  border-radius: 4px;
  color: rgba(255, 0, 0, 0.8);
  font-weight: bold;
}

th {
  font-weight: bold;
  font-size: 0.7rem;
  text-align: left;
}

td.iconContainer {
  text-align: center;
}

td.iconContainer img {
  width: 16px;
  cursor: pointer;
}

input,
select {
  padding: 4px;
  font-size: 0.7rem;
  width: 100%;
  max-width: 50ch;
}

button.secondary {
  background: var(--separator);
  color: var(--textShade);
  border: none;
  margin-top: 8px;
}

.section.actions .messageBox {
  margin-bottom: 16px;
}
</style>
