<template>
    <div class="App" :style="globalStyleVars" v-if="activeRoute">
        <div class="navigationMenu" :class="{ collapsed: collapsedNavigationMenu }">
            <navigation-menu :collapsed="isNavigationMenuCollapsed" v-on:collapsed-change="isNavigationMenuCollapsed = !isNavigationMenuCollapsed"></navigation-menu>
        </div>
        <div class="mainL1">
            <div class="globalHeader" v-show="!expandedView">
                <div class="bar">
                    <navigation-bar v-show="anchorPath"></navigation-bar>
                </div>
                <div class="search">
                    <search-control></search-control>
                </div>
                <div class="auth">
                    <span v-if="username">{{ username }}</span>
                    <span v-if="!username">Not logged in</span>
                    <span> &nbsp; </span>
                    <a v-if="username"  href="" v-on:click="logout">Log out</a>
                    <a v-if="!username" href="" v-on:click="login">Log in</a>
                </div>
            </div>
            <div class="pageHeader" v-show="!expandedView">
                <div class="name">
                    <h1>{{ anchorRoute.name }}</h1>
                </div>
                <div class="desc" v-show="anchorRoute.desc">
                    {{ anchorRoute.desc }}
                </div>
            </div>
            <div class="mainL2">
                <div class="notLoggedIn" v-if="!username && guestMode === null">
                    `<ethereal-box>
                        <div class="content">
                            <h1>Welcome to {{ config.portalName }}</h1>
                            <div class="actions">
                                <button class="action" v-on:click="guestMode = true" v-if="routes.length > 1">Continue as Guest</button>
                                <button class="action" v-on:click="login()">Log in</button>
                            </div>
                        </div>
                    </ethereal-box>`
                </div>
                <div class="topicMenu" v-if="anchorPath !== activePath" v-show="!expandedView">
                    <topic-menu></topic-menu>
                </div>
                <div class="mainL3">
                    <page-viewer v-if="activeRoute.type != 'collections'" :path="activePath"></page-viewer>
                    <collections-page v-if="activeRoute.type == 'collections'"></collections-page>
                    <div class="pageMenu">
                        <page-menu></page-menu>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import NavigationBar from './components/NavigationBar.vue';
    import SearchControl from './components/SearchControl.vue';
    import PageViewer from './components/PageViewer.vue';
    import CollectionsPage from './pages/CollectionsPage.vue';
    import NavigationMenu from './components/NavigationMenu.vue';
    import TopicMenu from './components/TopicMenu.vue';
    import PageMenu from './components/PageMenu.vue';
    import EtherealBox from './components/EtherealBox.vue';


    export default {
        components: { NavigationBar, SearchControl, PageViewer, CollectionsPage, NavigationMenu, TopicMenu, PageMenu, EtherealBox },
        inject: [ "router", "routes", "store" ],
        name: "App",
        data: function () {
            return {
                isNavigationMenuCollapsed: true,
                guestMode: null
            }
        },
        computed: {
            config: function () {
                return this.store.state.config;
            },
            globalStyleVars: function () {
                const css = {};
                
                if (!this.config.themes[this.activeTheme]
                    || !this.config.themes[this.activeTheme]["css"]
                    || !Array.isArray(this.config.themes[this.activeTheme]["css"])) {
                    console.error("The theme \"" + this.activeTheme + "\" wasn't found.");
                    return;
                }

                this.config.themes[this.activeTheme]["css"].forEach(({ attributeKey, value }) => {
                    css["--" + attributeKey] = value;
                });

                return css;
            },
            activePath: function () {
                return this.router.state.activePath;
            },
            activeRoute: function () {
                return this.router.state.activeRoute;
            },
            anchorPath: function () {
                return this.router.state.anchorPath;
            },
            anchorRoute: function () {
                return this.router.state.anchorRoute;
            },
            activeTheme: function () {
                return this.store.state.settings.activeTheme;
            },
            expandedView: function () {
                return this.store.state.settings.expandedView;
            },
            collapsedNavigationMenu: function () {
                return this.store.state.settings.collapsedNavigationMenu;
            },
            username: function () {
                const un = this.store.state.userData?.preferredUsername;
                return un;
            }
        },
        methods: {
            navigate: function (path) {
                location.href = "#" + path;
            },
            login: async function () {
                await this.store.login();
                Object.assign(this.routes, this.store.state.routes);
            },
            logout: async function () {
                await this.store.logout();
            }
        },
        watch: {
            activeRoute: function (newRoute, oldRoute) {
                // If a user navigates to a new route once an initial route is set, it's interpreted as wanting to browse as guest.

                if (oldRoute) this.guestMode = false;
            }
        }
    }
</script>

<style scoped>
    .App {
        display: flex;
        background: var(--bg);
        color: var(--textShade);
        min-height: 100vh;
    }

    .navigationMenu {
        flex: 0 0 auto;
        min-height: 100vh;
        height: inherit;
        width: 292px;
    }

    .navigationMenu.collapsed {
        width: 68px;
    }

    .mainL1 {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        border-left: 1px solid var(--strongSeparator);
    }

    .globalHeader {
        display: flex;
        width: 100%;
        flex: 0 0 auto;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row;
        background: var(--bg);
        padding: 24px 24px 0 24px;
    }

    .globalHeader .bar {
        align-content: center;
        flex: 1 0 auto;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        font-size: 0.9rem;
        margin-left: -4px; 
    }

    .globalHeader .search {
        flex: 0 0 250px;
    }

    .globalHeader .auth {
        flex: 0 0 auto;
        font-size: 0.75rem;
        margin-left: 24px;
        display: flex;
        align-items: center;
    }

    .globalHeader .auth a {
        margin-left: 24px;
    }

    .pageHeader {
        padding: 16px 24px 24px 24px;
        background: var(--bg);
        border-bottom: 1px solid var(--separator);
    }

    .pageHeader .desc {
        opacity: 0.9;
        margin-top: 8px;
        font-size: 0.8rem;
    }

    .mainL2 {
        display: flex;
        flex: 1 1 100%;
        flex-flow: column nowrap;
        width: 100%;
        background: var(--emptiness); 
    }

    .topicMenu {
        flex: 0 0 auto;
        background: var(--bg);
        border-bottom: 1px solid var(--separator);
    }

    .mainL3 {
        flex: 0 1 100%;
        display: flex;
    }

    @media (min-width: 1400px) { 
        .topicMenu {
            flex: 0 0 30%;
            max-width: 292px;
            border-right: 1px solid var(--separator);
        }

        .mainL2 {
            flex-flow: row nowrap;
        }
    }

    .mainL2 .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1 100%;
        height: 100%;
    }

    .pageMenu {
        z-index: 20;
        flex: 0 0 56px;
        border-left: 1px solid var(--separator);
    }

    .notLoggedIn .content > *:not(:first-child) {
        margin-top: 36px;
    }

    .notLoggedIn .actions {
        display: flex;
        flex-flow: row wrap;
    }

    .notLoggedIn .action {
        min-width: 180px;
        margin-bottom: 16px;
    }

    .notLoggedIn .action:not(:last-child) {
        margin-right: 32px;
    }

</style>
