<template>
    <div class="UploaderPage">
        <div class="uploader">
            <div class="header">
                <input type="file" ref="fileInput" multiple v-on:change="addFiles" /> &nbsp;
            </div>
            <div class="files" v-if="files.length > 0">
                <div class="fieldRow">
                    <div class="name">
                        <h3>File name</h3>
                    </div>
                    <div class="field">
                        <h3>Provider</h3>
                    </div>
                    <div class="field">
                        <h3>Table name</h3>
                    </div>
                    <div class="status">
                        <h3>Status</h3>
                    </div>
                    <div class="remove">
                    </div>
                </div>
                <div class="file" :class="{ 'expanded': file.expanded }" v-for="file, index in files" :key="index">
                    <div class="fieldRow">
                        <div class="name">
                            {{ file.rawFile.name }}<br />
                            <span class="size">{{ Math.ceil(file.rawFile.size/1024) + "kb" }}</span>
                        </div>
                        <div class="field">
                            <loading-box v-if="targetTables === null">Loading list...</loading-box>
                            <template v-else>
                                <input type="text" :list="`providersList_${ index }`" :disabled="file.processed === true" v-model="file.provider" v-on:change="() => { file.targetTableName = null; }" />
                                <datalist :id="`providersList_${ index }`">
                                    <option v-for="provider in new Set(targetTables.map(p => p.provider_id))" :key="provider" :value="provider"></option>
                                </datalist>
                            </template>
                        </div>
                        <div class="field">
                            <loading-box v-if="targetTables === null">Loading list...</loading-box>
                            <template v-else>
                                <input type="text" :list="`targetTablesList_${ index }`" :disabled="file.processed === true" v-model="file.targetTableName" />
                                <datalist :id="`targetTablesList_${ index }`">
                                    <template v-for="table in targetTables" :key="table.name"> 
                                        <option v-if="table.provider_id == file.provider" :value="table.name"></option>
                                    </template>
                                </datalist>
                            </template>
                        </div>
                        <div class="status">
                            <div class="pill" :class="file.job?.status ? file.job.status : 'Ready'" v-on:click="() => { if (file.job) file.expanded = !file.expanded; }">
                                {{ 
                                    file.job?.status ? file.job.status : "Ready" 
                                }}
                                {{ file.job?.status == "Successful" ? `(${ Math.floor(file.job.rows_added / file.job.rows_raw * 100) }%)` : "" }}
                                <svg width="8" height="7" viewBox="0 0 8 7" fill="none" v-if="file.job?.status">
                                    <path d="M1 1L4 6L7 1" />
                                </svg>
                           </div>
                        </div>
                        <div class="remove">
                            <img v-if="!file.processed" src="../../assets/delete.png" v-on:click="() => files.splice(index, 1)" />
                        </div>
                    </div>
                    <div class="jobContainer">
                        <div class="job" v-if="file.job && file.expanded" :class="file.job.status">
                            <div class="header">
                                <div class="heading">
                                    <div class="statusContainer">
                                        <div class="status" :class="file.job.status">{{ file.job.status }}</div>
                                        <div>{{ file.job.completion_message }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="main">
                                <div class="fields">

                                    <div class="field">
                                        <strong>Rows inputted</strong>
                                        {{ file.job.rows_raw || 0 }}
                                    </div>

                                    <div class="field">
                                        <strong>Rows added</strong>
                                        {{ file.job.rows_added || 0 }}
                                    </div>

                                    <div class="field">
                                        <strong>Started</strong>
                                        <template v-if="file.job.started">
                                            Started {{ new Date(file.job.started + "Z").toLocaleString("en-GB") }}
                                        </template>
                                        <template v-else-if="!file.job.started && !file.job.finished">
                                            Starting...
                                        </template>
                                        <template v-else>
                                            Couldn't start job
                                        </template>
                                    </div>

                                    <div class="field">
                                        <strong>Finished</strong>
                                        <template v-if="file.job.finished && file.job.finished !== true">
                                            {{ new Date(file.job.finished + "Z").toLocaleString("en-GB") }}
                                        </template>
                                        <template v-if="file.job.finished && file.job.finished === true">
                                            N/A
                                        </template>
                                        <template v-if="!file.job.finished">
                                            Not finished
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <div class="messages" v-if="file.job.messages?.length > 0">
                                <div class="selector">
                                    <template v-for="severity in new Set(file.job.messages.map(m => m.severity))" :key="severity">
                                        <input type="checkbox" v-model="file.messageSelector[severity]" checked /> {{ severityDesc[severity] }} ({{ file.job.messages.filter(m => m.severity == severity).length }})
                                    </template>
                                </div>
                                <div class="list">
                                    <template v-for="message, id in file.job.messages" :key="id">
                                        <div class="message" :class="`severity${message.severity}`" v-if="file.messageSelector[message.severity] !== false">
                                            <div class="pill" v-if="message.vfu_row_n !== null">
                                                Line {{ message.vfu_row_n }}
                                            </div>
                                            <div class="pill" v-if="message.column_name !== null">
                                                {{ message.column_name }}
                                            </div>
                                            {{ message.message }}
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="footer">
                                Job ID: {{ file.job.vfu_job_id ? file.job.vfu_job_id : "Not assigned" }} &middot;
                                Request GUID: {{ file.job.request_guid ? file.job.request_guid : "Not assigned" }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="empty" v-else>
                You can select <strong>one or multiple</strong> files at once.
            </div>
            
            <div class="buttonContainer">
                <button v-on:click="triggerProcessing" v-if="files.filter(v => v.processed !== true).length > 0">Upload</button>
                <loading-box v-if="files.filter(v => v.processed && !v.job?.uploaded && !v.job?.finished).length > 0">Uploading files... Please don't leave this page.</loading-box>
                <loading-box v-else-if="files.filter(v => v.processed && v.job?.uploaded && !v.job?.finished).length > 0">Processing files... You may leave this page and the process will continue in the background.</loading-box>
            </div>
        </div>

        

    </div>
</template>

<script>
    import LoadingBox from '../../components/LoadingBox.vue';

    export default {
        components: { LoadingBox },
        name: "UploaderPage",
        inject: ["router", "store"],
        props: {
            route: Object
        },
        data: function () {
            return {
                timeKey: 0,
                refreshTimer: null,
                targetTables: null,
                files: [],
                severityDesc: {
                    "1": "Warning",
                    "2": "Row Failure",
                    "3": "Fatal Error"   
                },
                apiBaseUrl: null
            }
        },
        created: function () {
            if (!this.route.payload?.apiBaseUrl) {
                console.error(`Cannot start file uploader. The attribute "apiBaseUrl" was not set in the payload for "${this.route.name}".`);
                return;
            }
            this.apiBaseUrl = this.route.payload.apiBaseUrl;
            
            Promise.all([ this.loadTargetTablesList() ]).then(() => {
                this.refreshJobs();
            });
        },
        unmounted: function () {
            clearTimeout(this.refreshTimer);
        },
        methods: {
            loadTargetTablesList: async function () {
                const url = `${this.apiBaseUrl}/targetTables`;
                
                const res = await fetch(url, {
                    headers: {
                        "Authorization": `Bearer ${ await this.store.refreshAndGetAuthToken() }`
                    }
                });
                const resBody = await res.json();

                this.targetTables = resBody.tables;
            },
            addFiles: function () {
                const e = this.$refs["fileInput"];

                for (let i = 0; i < e.files.length; i++) {
                    const rawFile = e.files[i];
                    const sizeMb = rawFile.size / 1024 / 1024;

                    if (sizeMb > 50) {
                        let msg = `The file ${ rawFile.name } is too big to be handled via this website. `;
                        msg += `Please split it in smaller files or contact your administrator to upload it directly into the backend system.` 
                        alert(msg)
                        continue;
                    }

                    this.files.push({
                        job: null,
                        rawFile,
                        expanded: false,
                        messageSelector: {}
                    });
                }

                e.value = null;
            },
            triggerProcessing: function () {
                this.files.filter(v => !v.processed).forEach(file => {
                    this.processFile(file);
                });
            },
            processFile: function (file) {
                const reader = new FileReader();

                file.processed = true;
                reader.readAsDataURL(file.rawFile);

                file.job = {
                    vfu_job_id: null,
                    file_name: file.rawFile.name,
                    provider: file.provider,
                    table_name: file.targetTableName,
                    request_guid: null,
                    status: "Uploading",
                    finished: null
                };
                
                reader.onload = async () => {
                    let res, resBody;

                    try {
                        const url = `${ this.apiBaseUrl }/upload`;
                        res = await fetch(url, {
                            method: "POST",
                            mode: "cors",
                            headers: {                               
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${ await this.store.refreshAndGetAuthToken() }`
                            },
                            body: JSON.stringify({
                                provider: file.provider,
                                targetTable: file.targetTableName,
                                fileName: file.rawFile.name,
                                fileData: reader.result
                            })
                        });
                    } catch {
                        Object.assign(file.job, {
                            status: "Failed",
                            completion_message: "Couldn't connect to the server. Please check that you're connected to the Internet.",
                            finished: true
                        });
                        return;
                    } 
                    
                    try {
                        resBody = await res.json();
                    } catch {
                        resBody = { message: null };
                    }

                    if (!res.ok) {
                        Object.assign(file.job, {
                            status: "Failed",
                            completion_message: resBody.message,
                            finished: true
                        });
                        return;
                    }

                    const requestGuid = resBody.request_guid;

                    Object.assign(file.job, {
                        uploaded: true,
                        request_guid: requestGuid,
                        status: "Waiting in queue"
                    });
                };

                reader.onerror = () => {
                    Object.assign(file.job, {
                        status: "Failed",
                        completion_message: "Couldn't read file",
                        finished: true
                    });
                };
            },
            refreshJobs: async function () {

                // Get jobs for which upload has happened (they have a request GUID) but haven't finished

                const jobs = this.files.map(f => f.job).filter(j => j && j.request_guid && !j.finished);

                // Abort if there's nothing to update

                if (jobs.length == 0) {
                    this.setRefreshTimer();
                    return;
                }

                // Create a dictionary to easily fetch and update jobs by request GUID
                
                const jobsByGuid = {};
                jobs.map(j => jobsByGuid[j.request_guid] = j); 

                const url = `${ this.apiBaseUrl }/jobs`

                try {
                    const res = await fetch(url, {
                        method: "POST",
                        headers: {
                            "Authorization": `Bearer ${ await this.store.refreshAndGetAuthToken() }`
                        },
                        body:  JSON.stringify({
                            guids: Object.keys(jobsByGuid)
                        })
                    });
                    const resBody = await res.json();
                    const newJobs = Object.values(resBody.jobs);

                    // Update jobs with the latest available data

                    newJobs.map(j => Object.assign(jobsByGuid[j.request_guid], j));
                } catch (e) {
                    console.error(e);
                    jobs.map(j => j.status = "Cannot check status, retrying");
                }

                this.setRefreshTimer();
            },
            setRefreshTimer: function () {
                if (this.timeKey == -1) return; 
                this.refreshTimer = setTimeout(this.refreshJobs, 1000);
            },
            messageTypeFromStatus: function (status) {
                if (status == "Failed") {
                    return "error";
                } else if (status == "Successful") {
                    return "success";
                } else {
                    return "info";
                }
            }
        }
    }
</script>

<style scoped>
    .UploaderPage {
        padding: 24px;
        font-size: 0.7rem;
    }

    .uploader {
        background: var(--bg);
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    }

    .uploader > .header {
        padding: 16px;
        border-bottom: 1px solid var(--separator);
    }

    .uploader > .header input {
        border: none;
    }

    .fieldRow {
        display: flex;
        align-items: center;
        margin: 16px;
    }

    .fieldRow > *:not(:first-child) {
        margin-left: 16px;
    }

    .fieldRow .size {
        color: var(--subtleTextShade);
    }

    .fieldRow .name {
        flex: 1 0 auto;
    }

    .fieldRow .field {
        flex: 0 0 30%;
    }

    .fieldRow .field select, .fieldRow .field input {
        padding: 2px;
        font-size: 0.7rem;
        width: 100%;
    }

    .fieldRow .status {
        flex: 0 0 150px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .fieldRow .status .pill {
        padding: 4px 8px 4px 8px;
        background: var(--subtleSeparator);
        border-radius: 16px;
        width: fit-content;
        user-select: none;
        display: flex;
        align-items: center;
        stroke: var(--separator);
    }

    .fieldRow .status .pill svg {
        height: 7px;
        opacity: 1;
        margin-left: 4px;
        transition: 0.2s ease-in-out all;
    }

    .expanded .fieldRow .status .pill svg {
        transform: rotate(180deg);
    }

    .fieldRow .status .pill:not(.Ready) {
        cursor: pointer;
    }

    .fieldRow .status .pill.Failed {
        background: rgba(255, 0, 0, 0.1);
        color: #F10000;
        stroke: #F10000;
    }

    .fieldRow .status .pill.Successful {
        background: rgba(0, 150, 0, 0.2);
        color: #005B00;
        stroke: #005B00;
    }

    .fieldRow .remove {
        text-align: center;
        flex: 0 0 16px;
    }

    .fieldRow .remove img {
        cursor: pointer;
        width: 16px;
    }

    .uploader .empty {
        padding: 16px;
    }

    .uploader .buttonContainer:not(:empty) {
        padding: 16px;
        display: flex;
    }

    .uploader .buttonContainer button {
        margin-right: 16px;
    }

    .jobContainer:empty {
        border-top: 1px solid var(--subtleSeparator);
    }

    .jobContainer:not(:empty) {
        box-shadow: 0 0px 12px 0 rgba(0, 0, 0, 0.1) inset;
        margin-left: -4px;
        margin-right: -4px;
    }

    .job {
        margin-left: 4px;
        margin-right: 4px;
        padding: 16px 16px 16px 12px;
        border-left: 4px solid var(--separator);
        border-top: 1px solid var(--separator);
        border-bottom: 1px solid var(--separator);
    }

    .job.Successful {
        border-left: 4px solid rgba(0, 150, 0, 0.2);
    }

    .job.Failed {
        border-left: 4px solid rgba(255, 0, 0, 0.2);
    }

    .job .heading .statusContainer {
        display: flex;
        align-items: center;
    }

    .job .status {
        color: white;
        background: #909090;
        padding: 4px 8px 4px 8px;
        border-radius: 16px;
        margin-right: 8px;
    }

    .job .status.Failed {
        background: #E21313;
    }

    .job .status.Successful {
        background: #249824;
    }

    .job .main {
        margin-top: 16px;
    }

    .job .fields {
        display: flex;
    }

    .job .fields .field {
        flex: 0 1 200px;
    }

    .job .fields .field strong {
        display: block;
        margin-bottom: 4px;
    }

    .job .footer {
        margin-top: 16px;
        color: var(--subtleTextShade);
    }

    .messages {
    }

    .messages .selector {
        margin-top: 16px;
        display: flex;
        align-items: center;
    }

    .messages .selector input {
        margin: 0 4px 0 0;
    }

    .messages .selector input:not(:first-child) {
        margin-left: 16px;
    }

    .messages .list {
        border-radius: 4px;
        max-height: 20vh;
        overflow-y: auto;
    }

    .messages .list:not(:empty) {
        margin-top: 16px;
    }

    .messages .message {
        padding: 12px;
        background: var(--subtleSeparator);
        line-height: 2.0;
    }

    .messages .message:not(:first-child) {
        margin-top: 1px;
        min-height: 20px;
    }

    .message::before {
        min-height: 20px;
        border-radius: 4px;
        padding: 4px;
        margin-right: 8px;
        font-size: 0.6rem;
    }

    .message.severity3::before {
        background: #E21313;
        content: "FATAL ERROR";
        color: white;
    }

    .message.severity2::before {
        background: #E21313;
        content: "ROW FAILURE";
        color: white;
    }

    .message.severity1::before {
        background: #FFBA0A;
        color: #49412C;
        content: "WARNING";
    }

    .message .pill {
        text-transform: uppercase;
        font-size: 0.6rem;
        padding: 4px;
        border-radius: 4px;
        margin-right: 8px;
        background: #c0c0c0;
        display: inline;
    }

</style>