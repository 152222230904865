<template>
    <div class="EtherealBox">
        <div class="bars" :style="{ '--barcount': barCount }">
            <div class="bar" v-for="i in barCount" :key="i" :style="{ 'margin-top': (Math.sin(i / waveFactor + ticker) * 150) + 'px', 'opacity': 1- Math.abs((i-barCount/2)/(barCount/2)) }"></div>
        </div>
        <div class="content">
            <slot></slot>
        </div>
    </div>
</template>

<script>

    export default {
        name: "EtherealBox",
        data: function () {
            return {
                ticker: 0
            }
        },
        props: {
            barCount: {
                type: Number,
                default: 36
            },
            waveFactor: {
                type: Number,
                default: 1.5
            }
        },
        methods: {
            incrementTicker: function () {
                this.ticker++;
                setTimeout(this.incrementTicker, 1000); 
            }
        },
        created: function () {
            this.incrementTicker();
        }
    }
</script>

<style scoped>
    .EtherealBox {
        position: absolute;
        top: 0;
        left: 0;
        background: var(--emptiness);
        color: var(--textShade);
        width: 100%;
        height: 100%;
        z-index: 99;
        padding: 32px;
        display: flex;
        flex-flow: column;
        align-items: center;
        overflow: hidden;
        justify-content: center;
    }

    .bars {
        transition: 1s all linear;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        min-width: calc(var(--barcount) * 60px);
    }

    .content {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .bar {
        transition: 1s;
        transition-property: all;
        transition-timing-function: linear;
        margin-left: 25px;
        height: 250px;
        width: 35px;
        border-radius: 24px;
        background: var(--bg);
    }


</style>