<template>
    <div class="NavigationBar">
        
        <template v-for="(route, index) in breadcrumbs" :key="index">
            <a :href="'#' + route.path">{{ route.name }}</a>
            <span v-if="index < breadcrumbs.length - 1">/</span>
        </template>
    </div>
</template>

<script>

    export default {
        name: "NavigationBar",
        inject: ["router", "routes"],
        computed: {
            breadcrumbs: function () {
                let path = this.router.state.anchorPath;
                if (path.slice(-1) == "/") path = path.slice(0, -1);

                const sh = path.split("/");
                const bc = [];

                for (let i = 0; i < sh.length; i++) {
                    const rs = sh.slice(0, i + 1).join("/") || "/";
                    const route = this.router.methods.matchRoute(rs);
                    if (route) bc.push(route);
                }

                return bc;
            }
        }
    }


</script>

<style scoped>

span {
    padding: 0 4px 0 4px;
}

a:last-child {
    opacity: 0.5;
}

</style>