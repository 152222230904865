<template>
    <div class="NavigationMenu" :class="{ collapsed }">
        <div v-on:click="toggleCollapsed()" class="collapser">
            <img class="colorInvertable" src="../assets/collapse.png" >
        </div>
        <div class="logo">
            <img :src="config.portalLogo">
            <div class="title" v-if="!collapsed">
                {{ config.portalName }}
            </div>
        </div>
        <div class="shortcuts">
            <div class="shortcutSection" v-for="(shortcuts, sectionKey) in shortcutSections" :key="sectionKey">
                <div v-for="(route, index) in shortcuts" :key="index" class="shortcut" :class="{ selected: selectedPath == route.path }" v-on:click="navigate(route)">
                    <div class="image">
                        <route-image class="colorInvertable" :route="route" :prefer-icon="true"></route-image>
                    </div>
                    <div class="name" v-show="!collapsed">
                        {{ route.name }}
                    </div>
                </div>
            </div>
        </div>
        <div class="mode">
            <div class="selector" v-show="!collapsed">
                <div class="button" v-on:click="setActiveTheme('light');">
                    <img class="colorInvertable" src="../assets/sun.png" >
                </div>
                <div class="button" v-on:click="setActiveTheme('dark');">
                    <img class="colorInvertable" src="../assets/moon.png" >
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="logoContainer">
                <distributor-logo :animated="false"></distributor-logo>
            </div>
            <div class="poweredBy" v-show="!collapsed">
                Powered by Vizify Portal
            </div>
        </div>
    </div>
</template>

<script>

    import RouteImage from '../components/RouteImage.vue';
    import DistributorLogo from './DistributorLogo.vue';
    
    export default {
        name: "NavigationMenu",
        inject: ["router", "routes", "store"],
        components: { RouteImage, DistributorLogo },
        methods: {
            navigate: function (route) {
                if (route.type == "link") {
                    window.open(route.payload.url);
                    return;
                }

                location.href = "#" + route.path;
            },
            setActiveTheme: function (s) {
                this.store.setActiveTheme(s);
            },
            toggleCollapsed: function () {
                this.store.setCollapsedNavigationMenu(!this.collapsed);
            }
        },
        computed: {
            config: function () {
                return this.store.state.config;
            },
            collapsed: function () {
                return this.store.state.settings.collapsedNavigationMenu;
            },
            selectedPath: function () {
                let sp = null;

                this.routes.forEach(route => {
                    if (route.shortcut != "true") return;                   

                    const path = route.path;

                    if (path !== "/" && this.router.state.activePath.startsWith(path)) {
                        
                        // Non-Home shortcuts are marked selected when the active path is the path of the shortcut or a subfolder.
                        
                        sp = path;
                    } else if (path === "/" && this.router.state.activePath === "/") {

                        // Home is only selected when the active path is empty, i.e. the user is in the homepage

                        sp = "/";
                    }
                });

                return sp;
            },
            shortcutSections: function () {
                const ss = [ [], [], [] ];

                this.routes.forEach(route => {
                    if (route.shortcut !== "true") return;
                    const path = route.path;

                    if (path === "/") {
                        ss[0].push(route);
                        return;
                    }

                    if (route.type === "collections") {
                        ss[2].push(route);
                        return;
                    }

                    ss[1].push(route);
                });

                const fss = ss.filter(f => f.length > 0);

                return fss;
            }
        }
    }


</script>

<style scoped>

    .NavigationMenu {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        height: 100%;
        background: var(--bg);
        position: static;
        width: inherit;
    }

    @media (min-height: 900px) { 
        .NavigationMenu {
            position: fixed;
        }
    }

    .collapser {
        cursor: pointer;
        text-align: right;
        padding: 24px 12px 24px 12px;
        user-select: none;
        border-bottom: 1px solid var(--subtleSeparator);
    }

    .collapser > img {
        width: 8px;
        transition: 0.2s ease-in-out all;
    }

    .collapsed .collapser > img {
        transform: rotate(180deg);
    }

    .logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 150px;
    }

    .logo > img {
        height: 80px;
    }

    .logo .title {
        margin-top: 16px;
        font-size: 0.85rem;
        line-height: 1.0;
    }

    .collapsed .logo > img {
        height: 48px;
    }

    .shortcutSection {
        border-top: 1px solid var(--subtleSeparator);
        padding-top: 12px;
    }

    .shortcutSection:not(:first-child) {
        margin-top: 12px;
    }

    .shortcut {
        padding-left: 8px;
        border-width: 0 0 0 4px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-style: solid;
        border-color: transparent;
    }

    .shortcut:not(:first-child) {
        margin-top: 8px;
    }

    .shortcut.selected {
        border-color: var(--selected);
    }

    .shortcut .image {
        flex: 0 0 44px;
        border-radius: 4px;
        height: 44px;
        overflow: hidden;
        filter: grayscale(100%);
    }

    .shortcut:hover {
        border-color: var(--selected);
    }

    .shortcut .name {
        flex: 1 0 auto;
        padding-left: 8px;
        font-size: 0.75rem;
    }

    .mode {
        flex: 1 0 auto;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        min-height: 96px;
    }

    .mode .selector {
        border-radius: 8px;
        overflow: hidden;
        width: 40%;
        height: 40px;
        display: flex;
    }

    .mode .button {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 0 auto;
        cursor: pointer;
        background: var(--subtleSeparator);
    }

    .mode div:not(:first-child) {
        margin-left: 1px;
    }

    .mode img {
        max-width: 20px;
        max-height: 20px;
    }

    .footer {
        flex: 0 0 auto;
        display: flex;
        font-size: 0.75rem; 
        padding: 0 24px 24px 24px;
    }

    .footer > .logoContainer {
        flex: 0 0 24px;
        height: 24px;
    }

    .footer > .poweredBy {
        flex: 1 0 auto;
        height: 24px;
        display: flex;
        align-items: center;
        padding-left: 16px;
        color: var(--subtleTextShade);
    }


</style>