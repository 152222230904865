<template>
    <div class="FormPage">
        <form-widget formId="workshopPriorities"></form-widget>
    </div>
</template>

<script>
    import FormWidget from '../components/FormWidget.vue'

    export default {
        components: { FormWidget },
        name: "FormPage",
        inject: ["router"],
        props: {
            route: Object
        }
    }
</script>

<style scoped>
    .FormPage {
        display: flex;
        width: 100%;
        height: 100%;
        flex-flow: row wrap;
        background: var(--bg);
    }

</style>