<template>
    <div class="CollectionPresenter" v-if="collection !== null">
        <div class="head">
            <div class="logo"><img :src="config.portalLogo"></div>
            <h2 class="name">{{ collection.name }}</h2>
            <div class="listContainer">
                <div v-if="isCollectionEmpty" class="empty">This collection is empty</div>
                <template  v-if="!isCollectionEmpty">
                    <div class="active" v-on:click="toggleList">
                        <div v-if="presenterPath" class="image"><route-image :route="presenterRoute"></route-image></div>
                        <div v-if="presenterPath" class="main">{{ presenterRoute.name }}</div>
                        <div v-if="!presenterPath" class="unselected">Select a page...</div>
                        <div class="toggler" :class="{ close: isListOpen }"><img src="../assets/open.png"></div>
                    </div>
                    <div class="list" v-if="isListOpen">
                        <div class="link" v-for="path in collection.paths" :key="path" v-on:click="presentPage(path)">
                            <div class="image"><route-image :route="router.methods.matchRoute(path)"></route-image></div>
                            <div class="main">{{ router.methods.matchRoute(path).name }}</div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="gap"></div>
            <div class="action" v-on:click="close"><img class="colorInvertable" src="../assets/stop.png"><span>End</span></div>
        </div>
        <div class="main">
            <page-viewer v-if="presenterPath !== null" :path="presenterPath"></page-viewer>
        </div>
    </div>
</template>

<script>
    import PageViewer from './PageViewer.vue';
    import RouteImage from './RouteImage.vue';

    export default {
        name: "CollectionPresenter",
        components: { RouteImage, PageViewer },
        emits: ["presenterClose"],
        inject: [ "routes", "router", "store" ],
        data: function () {
            return {
                presenterPath: null,
                isListOpen: false
            }
        },
        props: {
            collection: Object,
            initialPath: String
        },
        computed: {
            config: function () {
                return this.store.state.config;
            },
            presenterRoute: function () {
                return this.router.methods.matchRoute(this.presenterPath);
            },
            isCollectionEmpty: function () {
                return this.collection.paths.length === 0;
            }
        },
        created: function () {
            
            this.presenterPath = this.initialPath;
        },
        methods: {
            presentPage: function (path) {
                this.presenterPath = path;
                this.isListOpen = false;
            },
            toggleList: function () {
                this.isListOpen = !this.isListOpen;
            },
            close: function () {
                this.$emit("presenterClose");
            }
        }
    }
</script>

<style scoped>
    .CollectionPresenter {
        width: 100vw;
        height: 100vh;
        background: var(--emptiness);
        z-index: 200;
        display: flex;
        flex-flow: column nowrap;
    }

    .head {
        display: flex;
        flex-flow: row wrap;
        border-radius: 4px;
        align-items: center;
        background: var(--bg);
    }

    .head .logo {
        display: flex;
        align-items: center;
        padding: 8px 0 8px 0;
        margin-left: 16px;
        margin-right: 16px;
    }

    .head .logo img {
        height: 48px;
    }

    .head .name {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
        white-space: nowrap;
    }

    .head .listContainer {
        margin-left: 24px;
        position: relative;
        flex: 0 1 400px;
        max-width: calc(100vw - 32px);
        height: 64px;
        display: flex;
        flex-flow: row wrap;
    }

    .head .active {
        flex: 1 0 auto;
        border: 1px solid var(--separator);
        display: flex;
        overflow: hidden;
        margin: 8px 0 8px 0;
        border-radius: 8px;
        font-size: 0.75rem;
        align-items: center;
        cursor: pointer;
    }

    .head .empty {
        flex: 1 0 auto;
        border: 1px solid var(--separator);
        display: flex;
        overflow: hidden;
        padding-left: 16px;
        margin: 8px 0 8px 0;
        border-radius: 8px;
        font-size: 0.75rem;
        align-items: center;
    }

    .head .gap {
        flex: 1 0 auto;
    }

    .head > .action {
        flex: 0 0 auto;
        border: 1px solid var(--separator);
        display: flex;
        height: 50px;
        padding: 16px;
        margin: 8px 16px 8px 16px;
        border-radius: 8px;
        font-size: 0.75rem;
        align-items: center;
        cursor: pointer;
    }

    .head > .action img {
        width: 16px;
        height: 16px;
    }

    .head > .action span {
        margin-left: 16px;
    }

    .head .active .toggler {
        display: flex;
        width: 50px;
        align-items: center;
        justify-content: center;
    }

    .head .active .toggler img {
        width: 12px;
        height: 12px;
    }

    .head .active .toggler.close img {
        transform: rotate(180deg);
    }

    .head .active .unselected  {
        padding-left: 16px;
        flex: 1 0 auto;
    }

    .head .list {
        position: absolute;
        border-left: 1px solid var(--strongSeparator);
        border-right: 1px solid var(--strongSeparator);
        border-bottom: 1px solid var(--strongSeparator);
        box-shadow: 0 2px 20px -4px rgba(0, 0, 0, 0.2);
        border-radius: 0 0 8px 8px;
        max-width: calc(100vw - 32px);
        overflow: hidden;
        width: 400px;
        left: 0;
        top: 66px;
        z-index: 101;
    }

    .head .link {
        flex: 1 0 400px;
        cursor: pointer;
        overflow: hidden;
        max-width: 400px;
        display: flex;
        background: var(--bg);
    }

    .head .link:not(:last-child) {
        border-bottom: 1px solid var(--subtleSeparator);
    }

    .head .image {
        flex: 0 0 50px;
        height: 50px;
        font-size: 20px;
        background-color: var(--subtleSeparator);
        color: var(--bg);
        overflow: hidden;
    }    

    .head .main {
        padding: 8px;
        font-size: 0.75rem;
        display: flex;
        align-items: center;
        flex: 1 0 auto;
        z-index: 1;
    }

    .head .link:hover .main {
        background-color: var(--selected);
        color: var(--selectedText);
    }

    .CollectionPresenter > .main {
        flex: 1 0 auto;
        border-top: 1px solid var(--strongSeparator);
        background: var(--emptiness); 
    }

</style>