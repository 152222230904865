<template>
    <div class="FormWidget">
        <template v-if="status == 'loading'">
            <div class="loading" v-if="!formError.message">
                <loading-box>Loading...</loading-box>
            </div>
            <div class="errorContainer" v-if="formError.message">
                <message-box class="message" type="error">
                    {{ formError.message }}
                </message-box>
            </div>
        </template>
        <template v-if="status != 'loading'">
            <div class="segment head" v-if="def.title || def.desc">
                <h2 v-if="def.title">{{ def.title }}</h2>
                <div class="desc" v-if="def.desc">{{ def.desc }}</div>
            </div>
            <div v-for="(section, sectionIndex) in def.sections" :key="sectionIndex" class="segment">
                <h3 v-if="section.title">{{ section.title }}</h3>
                <div class="fieldContainer">
                    <div class="field" v-for="(field, fieldIndex) in section.fields" :key="fieldIndex" :class="{ error: fieldErrors[field.id] }" v-show="evaluateConditions(field.conditions)">
                        <span class="desc">{{ field.desc }}
                            <template v-if="field.required">*</template>
                        </span>
                        <div class="controlContainer" v-if="field.type == 'file'">
                            <input type="file" v-on:change="fileChange($event, field.id)">
                        </div>
                        <div class="controlContainer" v-if="field.type == 'text'">
                            <input type="text" v-on:input="fieldChange($event, field)" :value="values[field.id][1]">
                        </div>
                        <div class="controlContainer" v-if="field.type == 'number'">
                            <input type="number" v-on:input="fieldChange($event, field)" :value="values[field.id][1]">
                        </div>
                        <div class="controlContainer" v-if="field.type == 'select'">
                            <select v-on:change="fieldChange($event, field)">
                                <option :value="option.value" v-for="(option, index) in getOptions(field)" :key="index" :selected="option.value == values[field.id][1]">{{ option.desc || option.value }}</option>
                            </select>
                        </div>
                        <div class="controlContainer ab" v-if="field.type == 'ab'">
                            <form-control-a-b v-on:change="componentFieldChange($event, field)" :options="getOptions(field)"></form-control-a-b>
                        </div>
                        <message-box class="message" type="error" v-if="fieldErrors[field.id]">{{ fieldErrors[field.id].message }}</message-box>
                        <div class="comment">{{ field.comment }}</div>
                    </div>
                </div>
            </div>
            <div class="segment actions">
                <message-box class="message" :type="actionResult.type" v-if="actionResult">
                    <template v-if="actionResult.message">
                        {{ actionResult.message }}
                    </template>
                    <template v-if="!actionResult.message && actionResult.type == 'error'">
                        Error.
                    </template>
                    <template v-if="!actionResult.message && actionResult.type == 'success'">
                        Success.
                    </template>
                </message-box>
                <button v-on:click="submit">{{ def.submitLabel || "SUBMIT" }}</button>
                <div class="submitting" v-if="status == 'submitting'">
                    <loading-box>Submitting...</loading-box>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import MessageBox from './MessageBox.vue'
import FormControlAB from './FormControlAB.vue'
import LoadingBox from './LoadingBox.vue'

export default {
    components: { MessageBox, FormControlAB, LoadingBox },
    name: "FormWidget",
    inject: [ "router" ],
    data: function () {
        return {
            status: "loading",
            formError: {},
            def: {},
            fieldErrors: {},
            values: {},
            actionResult: null,
            conditionEval: {}
        }
    },
    methods: {
        initialiseValues: function (initialValues) {
            /*
            Values are saved in this.values in two-element arrays containing id and value. Field id is used as a key.            
            For example, the field "tomatoSize" will be saved as:
            this.values["tomatoSize"] = [ "tomatoSize", "4"];
            This data structure was designed to accommodate promises and Promise.all during form submission.
            */

            /*
            this.values are set only when the control (e.g. input[type="text"]) is interacted with.
            The form submission doesn't read the control's value, it only reads this.values.
            The values set by default here will be the ones sent if the user doesn't interact with the controls. 
            */

            this.def.sections.forEach(section => {
                section.fields.forEach(field => {

                    // Initial value > Default value > First option > Empty string 

                    if (initialValues[field.id]) {
                        this.values[field.id] = [field.id, initialValues[field.id]];
                    } else if (field.default) {
                        this.values[field.id] = [field.id, field.default];
                    } else if (field.type == "select" && field.options?.length > 0) {
                        this.values[field.id] = [field.id, field.options[0].value];
                    } else {
                        this.values[field.id] = [field.id, ""]; // Empty string is the default default value
                    }
                });
            });
        },
        submit: function () {

            this.status = "submitting";

            // Wait until all values are available

            Promise.all(Object.values(this.values)).then(vs => {
                
                // vs contains an array of two-element arrays, containing field id and value.

                const reqBody = { values: {} };

                vs.forEach(v => {
                    reqBody.values[v[0]] = v[1];
                });
                
                fetch(`${ process.env.PORTAL_API_BASE_URL }/formValues/${ this.formId }`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    mode: "cors",
                    body: JSON.stringify(reqBody)
                })
                .then(res => 
                    Promise.all([res, res.json()])
                )
                .then(([res, data]) => {
                    this.actionResult = {};
                    this.fieldErrors = {};

                    if (res.status > 299) {
                        this.actionResult.type = "error";
                    } else {
                        this.actionResult.type = "success";
                    }

                    if (data.fieldErrors) this.fieldErrors = data.fieldErrors;

                    this.actionResult.message = data.message;
                    this.router.methods.updateVersion();                    
                }).catch(() => {
                    this.formError = "Couldn't submit data. Please check your connection and try again later.";
                }).finally(() => {
                    this.status = "loaded";
                });
            });
        },
        fieldChange: function (event, field) {
            const control = event.target;
            const value = control.value;
            const fieldId = field.id;
            const regex = field.regex;

            this.values[fieldId] = [fieldId, value];

            if (value && regex) {
                const r = new RegExp(regex); 
            
                if (!r.test(value)) {
                    this.fieldErrors[fieldId] = { message: "The value entered doesn't match the expected pattern." }; 
                    return;
                }
            }

            this.fieldErrors[fieldId] = null;
        },
        componentFieldChange: function (event, field) {

            // Custom controls implemented as components provide the value in their change event payload

            const value = event.value;
            const fieldId = field.id;

            this.values[fieldId] = [fieldId, value];
        },
        fileChange: function (event, fieldId) {
            const input = event.target;

            // The field value is a promise to allow time to process the file from binary to data URL

            this.values[fieldId] = new Promise((resolve, reject) => {

                // The file is read and saved as a data URL 

                const file = input.files[0];

                if (!file) resolve("");

                const reader = new FileReader();

                reader.readAsDataURL(file);
                reader.onload = () => resolve([fieldId, reader.result]);
                reader.onerror = (err) => reject(err);
            });
        },
        evaluateConditions: function (conditions) {           
            if (!conditions) return true;

            let b = true;

            conditions?.forEach(condition => {
                if (!condition.values.includes(this.values[condition.field][1])) b = false;
            });

            return b;
        },
        getOptions: function (field) {
            const fo = field.options.filter(o => this.evaluateConditions(o.conditions));  

            return fo;
        }
    },
    props: {
        formId: String
    },
    beforeCreate: function () {
        fetch(`${ process.env.PORTAL_API_BASE_URL }/form/${ this.formId }`, {
        })
        .then(res => 
            Promise.all([res, res.json()])
        )
        .then(([res, data]) => {
            if (res.status > 299) {
                this.formError = { message: `Couldn't load form ${this.formId}. Status ${res.status}.` };
                return;
            }

            this.def = data.def;
            this.initialiseValues(data.initialValues);
            this.status = "loaded";
        }).catch(err => {
            console.error(err);
            this.formError = { message: `Couldn't load form ${this.formId}.` };
        });
    }
}
</script>

<style scoped>
    .FormWidget {
        background: var(--bg);
        width: 100%;
    }

    .loading {
        padding: 24px;
    }

    .errorContainer {
        padding: 16px;
    }

    .segment {
        padding: 24px;
    }

    .segment:not(:first-child) {
        border-top: 1px solid var(--subtleSeparator);
    }

    .head .desc {
        font-size: 0.75rem;
        margin-top: 8px;
    }

    .fieldContainer {
        margin-right: -48px;
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .field {
        flex: 0 1 70ch;
        z-index: 1;
        position: relative;
        max-width: 70ch;
        margin-top: 16px;
        margin-right: 48px;
    }

    .field > .desc {
        position: relative;
        font-size: 0.7rem;
        background: var(--bg);
        z-index: 2;
        padding: 0 3px 3px 3px;
        left: 5px;
    }

    .field > .message {
        margin-top: 8px;
    }

    .field > .comment {
        font-size: 0.7rem;
        margin-top: 8px;
        color: rgba(0, 0, 0, 0.5);
    }

    .controlContainer {
        margin-top: -10px;
    }

    .controlContainer input {
        width: 100%;
        font-size: 0.7rem;
        padding: 12px 8px 8px 8px;
    }

    .controlContainer select {
        width: 100%;
        font-size: 0.7rem;
        padding: 12px 3px 8px 3px;
    }

    .controlContainer.ab {
        font-size: 0.7rem;
        border: 1px solid var(--strongSeparator);
        padding: 8px;
        border-radius: 4px;
    }

    .controlContainer.ab .message {
        font-size: 0.7rem;
        text-align: right;
        margin-bottom: 8px;
    }

    .controlContainer.ab .optionComparison {
        grid-gap: 8px;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        display: grid;
    }

    .controlContainer.ab .optionComparison div {
        background: var(--subtleSeparator);
        border-radius: 4px;
        padding: 16px;
        text-align: center;
        cursor: pointer;
    }

    .controlContainer.ab .optionComparison .optionAB {
        min-height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .controlContainer.ab .optionComparison .optionEqual {
        grid-column-start: 1;
        grid-column-end: 3;
    }
    
    .controlContainer.ab .optionOrder div {
        background: var(--subtleSeparator);
        border-radius: 4px;
        padding: 16px;
        cursor:grab;
        margin-top: 8px;
    }

    .field.error input, .field.error select {
        border-color: red;
    }

    .field.error span {
        color: red;
    }

    .actions .message {
        margin-bottom: 8px;
    }

    .submitting {
        display: inline-block;
        margin-left: 16px;
    }

</style>
