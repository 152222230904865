<template>
    <div class="UploaderJobsPage">
        <div class="filter">
            <div class="fields">
                <div class="main">
                    <input type="text" placeholder="Search terms..." v-model="filterBox.text" />
                </div>
                <div class="field">
                    From <input type="date"  v-model="filterBox.fromDate" />
                </div>
                <div class="field">
                    Until <input type="date"  v-model="filterBox.toDate" />
                </div>
            </div>
            <div>
                <button v-on:click="filter">FILTER</button>&nbsp;
                <button v-on:click="clearFilter">CLEAR</button>
            </div>
        </div>
        <loading-box v-if="jobs === null">Loading...</loading-box>
        <div v-else class="jobContainer">
            <div class="job" v-for="[jobId, job] in Object.entries(jobs).sort((e1, e2) => e2[1].vfu_job_id-e1[1].vfu_job_id)" :key="jobId">
                <div class="header" :class="job.rolled_back === 1 ? 'RolledBack' : job.status">
                    <div class="bar" :key="timeKey">
                        {{ calculateTimeAgo(new Date(job.started + "Z")) }}
                        <template v-if="showRollback && job.rolled_back !== 1 && job.status == 'Successful'">
                            &nbsp;&middot;&nbsp;<span class="action" v-on:click="rollBack(jobId, $event.target)">Roll back</span>
                        </template>
                        <template v-if="showDownload">
                            &nbsp;&middot;&nbsp;<span class="action" v-on:click="downloadFile(jobId)">Download file</span>
                        </template>
                    </div>               
                    <div class="heading">
                        <h2>{{ job.file_name }}</h2> 
                        <div class="statusContainer">
                            <div class="status" :class="job.rolled_back === 1 ? 'RolledBack' : job.status">
                                {{ job.rolled_back === 1 ? "Rolled back &middot; " : "" }}
                                {{ job.status }}
                                {{ job?.status == "Successful" ? `(${ Math.floor(job.rows_added / job.rows_raw * 100) }%)` : "" }}
                            </div>
                            <div>{{ job.completion_message }}</div>
                        </div>
                    </div>
                </div>
                <div class="main">
                    <div class="fields">
                        <div class="field">
                            <strong>Started</strong>
                            {{ new Date(job.started + "Z").toLocaleString("en-GB") }}
                        </div>

                        <div class="field">
                            <strong>Provider</strong>
                            {{ job.provider }}
                        </div>

                        <div class="field">
                            <strong>Table name</strong>
                            {{ job.table_name }}
                        </div>

                        <div class="field">
                            <strong>Uploader</strong>
                            {{ job.username }}
                        </div>

                        <div class="field">
                            <strong>Rows inputted</strong>
                            {{ job.rows_raw || 0 }}
                        </div>

                        <div class="field">
                            <strong>Rows added</strong>
                            {{ job.rows_added || 0 }}
                        </div>

                        <div class="field">
                            <strong>Finished</strong>
                            <template v-if="job.finished && job.finished !== true">
                                {{ new Date(job.finished + "Z").toLocaleString("en-GB") }}
                            </template>
                            <template v-if="job.finished && job.finished === true">
                                N/A
                            </template>
                            <template v-if="!job.finished">
                                Not finished
                            </template>
                        </div>
                    </div>
                </div>
                <div class="messages" v-if="job.messages?.length > 0">
                    <div class="selector">
                        <template v-for="severity, id in new Set(job.messages.map(m => m.severity))" :key="id">
                            <input type="checkbox" v-model="messageSelector[`${jobId}_${ severity }`]" checked /> {{ severityDesc[severity] }} ({{ job.messages.filter(m => m.severity == severity).length }})
                        </template>
                    </div>
                    <div class="list">
                        <template v-for="message, id in job.messages" :key="id">
                            <div class="message" :class="`severity${message.severity}`" v-if="messageSelector[`${ jobId }_${ message.severity }`] !== false">                                
                                <div class="pill" v-if="message.vfu_row_n !== null">
                                    Line {{ message.vfu_row_n }}
                                </div>
                                <div class="pill" v-if="message.column_name !== null">
                                    {{ message.column_name }}
                                </div>{{ message.message }}
                            </div>
                        </template>
                    </div>
                </div>
                <div class="footer">
                    Job ID: {{ job.vfu_job_id ? job.vfu_job_id : "Not assigned" }} &middot;
                    Request GUID: {{ job.request_guid ? job.request_guid : "Not assigned" }}
                </div>
            </div>
            <button v-if="loadedAll === false" v-on:click="() => { loadJobs(); }" class="loadMore">
                LOAD MORE
            </button>
        </div>

    </div>
</template>

<script>
    import LoadingBox from '../../components/LoadingBox.vue';

    export default {
        components: { LoadingBox },
        name: "UploaderJobsPage",
        inject: ["router", "store"],
        props: {
            route: Object
        },
        data: function () {
            return {
                timeKey: 0,
                refreshTimer: null,
                checkForNewTimer: null,
                jobs: null,
                loadedAll: null,
                messageSelector: {},
                apiBaseUrl: null,
                showDownload: null,
                showRollback: null,
                severityDesc: {
                    "1": "Warning",
                    "2": "Row Failure",
                    "3": "Fatal Error"   
                },
                filterBox: {},
                filterParameters: {} // Snapshot of filterBox 
            }
        },
        created: async function () {
            if (!this.route.payload?.apiBaseUrl) {
                console.error(`Cannot start file uploader. The attribute "apiBaseUrl" was not set in the payload for "${this.route.name}".`);
                return;
            }
            this.apiBaseUrl = this.route.payload.apiBaseUrl;

            await this.loadJobs();
            await this.checkForNewJobs();
            await this.refreshJobs();
        },
        unmounted: function () {
            clearTimeout(this.refreshTimer);
            clearTimeout(this.checkForNewTimer);
            this.timeKey = -1;
        },
        methods: {
            filter: function () {
                this.jobs = null;
                Object.assign(this.filterParameters, this.filterBox); 
                this.loadJobs();
            },
            clearFilter: function () {
                this.jobs = null;
                this.filterBox = { text: null, fromDate: null, toDate: null };
                Object.assign(this.filterParameters, this.filterBox);
                this.loadJobs();
            },
            loadJobs: async function () {
                const { text, fromDate, toDate } = this.filterParameters;            
                const minJobId = this.jobs ? Math.min(...Object.values(this.jobs).map(j => j.vfu_job_id)) : undefined;
                const res = await fetch(`${ this.apiBaseUrl }/jobs`, {
                    method: "POST",
                    headers: {
                        "Authorization": `Bearer ${ await this.store.refreshAndGetAuthToken() }`
                    },
                    body: JSON.stringify({
                        jobIdLT: minJobId,
                        text,
                        fromDate,
                        toDate
                    })
                });
                const resBody = await res.json();
                const matchingCount = resBody.matchingCount;
                const newJobs = resBody.jobs;

                this.jobs = {...this.jobs, ...newJobs};
                this.showDownload = resBody["show_download"];
                this.showRollback = resBody["show_rollback"];

                if (matchingCount > Object.values(newJobs).length) {
                    this.loadedAll = false;
                } else {
                    this.loadedAll = true;
                }

                this.checkForNewJobs();
            },
            checkForNewJobs: async function () {
                if (!this.jobs) {
                    clearTimeout(this.checkForNewTimer);
                    if (this.timeKey == -1) return;
                    this.checkForNewTimer = setTimeout(this.checkForNewJobs, 5000);
                    return;
                }

                const { text, fromDate, toDate } = this.filterParameters;            
                const maxJobId = Math.max(...Object.values(this.jobs).map(j => j.vfu_job_id));
                const url = `${ this.apiBaseUrl }/jobs`

                try {
                    const res = await fetch(url, {
                        method: "POST",
                        headers: {
                            "Authorization": `Bearer ${ await this.store.refreshAndGetAuthToken() }`
                        },
                        body:  JSON.stringify({
                            orderDesc: false,
                            jobIdGT: maxJobId,
                            text,
                            fromDate,
                            toDate
                        })
                    });
                    const resBody = await res.json();
                    const matchingCount = resBody.matchingCount;
                    const newJobs = resBody.jobs;

                    if (matchingCount > 0) {
                        this.jobs = {...this.jobs, ...newJobs};
                    }
                } catch (e) {
                    console.error(e);
                }

                clearTimeout(this.checkForNewTimer);
                if (this.timeKey == -1) return;
                this.checkForNewTimer = setTimeout(this.checkForNewJobs, 5000);
            },
            refreshJobs: async function () {
                
                const unfinishedJobs = this.jobs ? Object.values(this.jobs).filter(j => j && !j.finished) : []; 

                if (unfinishedJobs.length == 0) {
                    clearTimeout(this.refreshTimer);
                    if (this.timeKey == -1) return;
                    this.refreshTimer = setTimeout(this.refreshJobs, 1000);
                    return;
                }

                this.timeKey++; // Used to re-trigger calculateTimeAgo from the template

                const url = `${ this.apiBaseUrl }/jobs`

                try {
                    const res = await fetch(url, {
                        method: "POST",
                        headers: {
                            "Authorization": `Bearer ${ await this.store.refreshAndGetAuthToken() }`
                        },
                        body:  JSON.stringify({
                            jobIds: unfinishedJobs.map(j => j.vfu_job_id)
                        })
                    });
                    const resBody = await res.json();
                    const newJobs = resBody.jobs;

                    // Update jobs with the latest available data

                    Object.values(newJobs).map(j => Object.assign(this.jobs[j.vfu_job_id], j));
                } catch (e) {
                    console.error(e);
                    this.jobs.map(j => j.status = "Cannot check status, retrying");
                }

                clearTimeout(this.refreshTimer);
                if (this.timeKey == -1) return;
                this.refreshTimer = setTimeout(this.refreshJobs, 1000);
            },
            messageTypeFromStatus: function (status) {
                if (status == "Failed") {
                    return "error";
                } else if (status == "Successful") {
                    return "success";
                } else {
                    return "info";
                }
            },
            calculateTimeAgo: function (d) {
                const ds = Math.floor((new Date() - d) / 1000); // Seconds ago

                if (ds < 60) {
                    return `Less than a minute ago`;
                }

                if (ds < 60*60) {
                    const n = Math.floor(ds/60);
                    return `${ n } minute${ n > 1 ? 's' : '' } ago`
                }

                if (ds < 60*60*24) {
                    const n = Math.floor(ds/(60*60));
                    return `${ n } hour${ n > 1 ? 's' : '' } ago`
                }

                if (ds < 60*60*24*365.25) {
                    const n = Math.floor(ds/(60*60*24));
                    return `${ n } day${ n > 1 ? 's' : '' } ago`
                }

                const n = Math.floor(ds/(60*60*24*362.25));
                return `${ n } years${ n > 1 ? 's' : '' } ago`
            },
            rollBack: async function (jobId, element) {
                element.innerHTML = "Rolling back...";
                try {
                    const res = await fetch(`${ this.apiBaseUrl }/rollBack`, {
                        method: "POST",
                        headers: {
                            "Authorization": `Bearer ${ await this.store.refreshAndGetAuthToken() }`
                        },
                        body: JSON.stringify({
                            jobId
                        })
                    });
                    if (res.ok) {
                        this.jobs[jobId].rolled_back = 1;
                    } else {
                        const body = await res.json();
                        let msg = "Roll back failed. "
                        if (body?.message) {
                            msg += body.message;
                        }
                        alert(msg);
                    }
                } catch (e) {
                    alert("Roll back failed.");
                }
                element.innerHTML = "Roll back";
            },
            downloadFile: async function (jobId) {
                const res = await fetch(`${ this.apiBaseUrl }/downloadFile`, {
                    method: "POST",
                    headers: {
                        "Authorization": `Bearer ${ await this.store.refreshAndGetAuthToken() }`
                    },
                    body: JSON.stringify({
                        jobId
                    })
                });
                if (!res.ok) {
                    const body = await res.json();
                    alert(`${ body?.message }`);
                }

                const blob = await res.blob();
                const url = URL.createObjectURL(blob);
                const linkElement = document.createElement("a");
                linkElement.download = this.jobs[jobId].file_name ?? `job${ jobId }`;
                linkElement.href = url;
                linkElement.click();
                URL.revokeObjectURL(url);
            }
        }
    }
</script>

<style scoped>
    .UploaderJobsPage {
        padding: 24px;
        font-size: 0.7rem;
    }

    .filter {
        background: var(--bg);
        padding: 16px;
        margin-bottom: 24px;
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    }

    .filter .fields {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }

    .filter .fields > *:not(:first-child) {
        margin-left: 16px;
    }

    .filter .fields .main {
        flex: 1 0 auto;
    }

    .filter .fields .main > input {
        width: 80%;
    }

    .filter .fields .field {
        flex: 0 0 20%;
        text-align: right;
    }

    .filter .fields .field input {
        padding: 2px;
        margin-left: 8px;
        font-size: 0.7rem;
    }

    .job {
        background: white;
        background: var(--bg);
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    }

    .job:not(:first-child) {
        margin-top: 24px;
    }

    .job .header {
        padding: 16px;
        background: rgba(0, 0, 0, 0.05);
        transition: 1s all ease-in-out;
    }

    .job .header.RolledBack {
        background: rgba(0, 0, 0, 0.2);
    }

    .job .header.Successful {
        background: rgba(0, 150, 0, 0.2);
    }

    .job .header.Failed {
        background: rgba(255, 0, 0, 0.1);
    }

    .job .header .bar {
        font-size: 0.7rem;
        margin-bottom: 16px;
    }

    .job .header .bar .action {
        cursor: pointer;
    }

    .job .header .bar .action:hover {
        text-decoration: underline;
    }

    .job .heading h2 {
        margin-bottom: 16px;
    }

    .job .heading .statusContainer {
        display: flex;
        align-items: center;
    }

    .job .status {
        color: white;
        background: #909090;
        padding: 4px 8px 4px 8px;
        border-radius: 16px;
        margin-right: 8px;
    }

    .job .status.Failed {
        background: #E21313;
    }

    .job .status.Successful {
        background: #249824;
    }

    .job .status.RolledBack {
        background: #303030;
    }

    .job .main {
        padding: 16px;
    }

    .job .fields {
        display: flex;
    }

    .job .fields .field {
        flex: 0 1 300px;
    }

    .job .fields .field strong {
        display: block;
        margin-bottom: 4px;
        font-size: 0.75rem;
    }

    .job .footer {
        padding: 16px;
        color: var(--subtleTextShade);
    }

    .messages {
        border-top: 1px solid var(--subtleSeparator);
    }

    .messages .selector {
        padding: 8px 16px 8px 16px;
        display: flex;
        align-items: center;
    }

    .messages .selector input {
        margin: 0 4px 0 0;
    }

    .messages .selector input:not(:first-of-type) {
        margin-left: 16px;
    }

    .messages .list {
        max-height: 20vh;
        overflow-y: auto;
    }

    .messages .message {
        padding: 12px;
        background: var(--subtleSeparator);
        line-height: 2.0;
    }

    .messages .message:not(:first-child) {
        margin-top: 1px;
        min-height: 20px;
    }

    .message::before {
        min-height: 20px;
        border-radius: 4px;
        padding: 4px;
        margin-right: 8px;
        font-size: 0.6rem;
    }

    .message.severity3::before {
        background: #E21313;
        content: "FATAL ERROR";
        color: white;
    }

    .message.severity2::before {
        background: #E21313;
        content: "ROW FAILURE";
        color: white;
    }

    .message.severity1::before {
        background: #FFBA0A;
        color: #49412C;
        content: "WARNING";
    }

    .message .pill {
        text-transform: uppercase;
        font-size: 0.6rem;
        padding: 4px;
        border-radius: 4px;
        margin-right: 8px;
        background: #c0c0c0;
        display: inline;
    }

    .loadMore {
        margin-top: 24px;
    }

</style>