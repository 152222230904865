<template>
    <div class="NavigationCollections">
        <div class="loading box" v-if="collections === null">
            Loading collections...
        </div>
        <div class="list box" v-if="collections !== null">
            <h3>COLLECTIONS</h3>
            <div class="empty" v-if="Object.keys(collections).length == 0">
                You don't have any collections.
            </div>
            <div class="collection" v-for="(collection, key) in collections" v-bind:key="key">
                <template v-if="!actions[key] || actions[key].mode == 'view'">
                    <div class="content">
                        <input type="checkbox" :checked="collection.paths.indexOf(activePath) !== -1" v-on:change="handleSaveChange($event, key)">
                        <span>{{ collection.name }}</span>
                    </div>
                    <div class="action edit" v-on:click="actions[key].mode = 'edit'; actions[key].editedName = collection.name;"><img class="colorInvertable" src="../assets/edit.png" /></div>
                    <div class="action delete" v-on:click="actions[key].mode = 'delete'"><img class="colorInvertable" src="../assets/delete.png" /></div>
                </template>
                <template v-if="actions[key]?.mode == 'edit'">
                    <div class="content">
                        <input type="text" v-model="actions[key].editedName">
                    </div>
                    <span class="action edit" v-on:click="editCollection(key)">Confirm</span>
                    <span class="action edit" v-on:click="actions[key].mode = 'view'">Cancel</span>
                </template>
                <template v-if="actions[key]?.mode == 'deleting'">
                    <div class="content">
                        <loading-box>Deleting...</loading-box>
                    </div>
                </template>
                <template v-if="actions[key]?.mode == 'updating'">
                    <div class="content">
                        <loading-box>Updating...</loading-box>
                    </div>
                </template>
                <template v-if="actions[key]?.mode == 'delete'">
                    <div class="content">
                        <span>Delete <strong>{{ collection.name }}</strong>?</span>
                    </div>
                    <span class="action edit" v-on:click="deleteCollection(key)">Delete</span>
                    <span class="action edit" v-on:click="actions[key].mode = 'view'">Cancel</span>
                </template>
            </div>
            <div v-if="errorMessage" class="errorMessage">
                <message-box type="error">{{ errorMessage }}</message-box>
            </div>
        </div>
        <div class="create box" v-if="collections !== null">
            <h3>CREATE COLLECTION</h3>
            <div class="content">
                <input type="text" v-model="createName" placeholder="Name the new collection...">
            </div>
            <div class="action">
                <button v-on:click="createCollection">CREATE NEW</button>
                <loading-box v-if="createMode == 'creating'">Creating...</loading-box>
            </div>
            <message-box type="error" v-if="createErrorMessage" class="createErrorMessage">{{ createErrorMessage }}</message-box>
        </div>
    </div>
</template>

<script>
    import LoadingBox from './LoadingBox.vue';
    import MessageBox from './MessageBox.vue';

    export default {
        name: "NavigationCollections",
        components: { MessageBox, LoadingBox },
        inject: ["router", "store"],
        data: function () {
            return {
                isVisible: false,
                errorMessage: null,
                createMode: "view",
                createName: "",
                createErrorMessage: null,
                actions: {}
            }
        },
        computed: {
            activePath: function () {

                // Compute the activePath to link a watcher to it

                return this.router.state.activePath;
            },
            collections: function () {
                return this.store.state.collections;
            },
            savedInCount: function () {
                return 0;
                /*
                const reducer = (acc, cur) => cur[1].paths.indexOf(this.activePath) !== -1 ? ++acc : acc;
                const c = Object.entries(this.collections).reduce(reducer, 0);

                return c;*/
            }
        },
        watch: {
            "store.state.collections": {
                handler: function () {
                    if (!this.collections) return;

                    Object.keys(this.collections).forEach(key => {
                        if (this.actions[key] === undefined) {
                            this.actions[key] = { mode: "view" };
                        }
                    });
                },
                immediate: true
            }
        },
        methods: {
            toggleVisibility: function () {
                this.isVisible = !this.isVisible;
            },
            handleSaveChange: function (event, key) {
                const checked = event.target.checked;

                if (checked) {
                    this.addPath(key, this.activePath);
                } else {
                    this.removePath(key, this.activePath);
                }
            },
            addPath: function (key) {
                this.actions[key].mode = "updating";

                this.store.addPath(key, this.activePath).then(() => {
                    this.actions[key].mode = "view";
                    this.errorMessage = null;
                }).catch(err => {
                    this.actions[key].mode = "view";
                    this.errorMessage = err;
                });
            },
            removePath: function (key) {
                this.actions[key].mode = "updating";

                this.store.removePath(key, this.activePath).then(() => {
                    this.actions[key].mode = "view";
                    this.errorMessage = null;
                }).catch(err => {
                    this.actions[key].mode = "view";
                    this.errorMessage = err;
                });
            },
            editCollection: function (key) {

                // Performs the edit once confirmed

                const editedName = this.actions[key].editedName;
                
                this.actions[key].mode = "updating";

                this.store.modifyCollection(key, editedName).then(() => {
                    this.actions[key].mode = "view";
                    this.errorMessage = null;
                }).catch(err => {
                    this.actions[key].mode = "view";
                    this.errorMessage = err;
                });
            },
            deleteCollection: function (key) {

                // Performs the delete once confirmed
                
                this.actions[key].mode = "deleting";

                this.store.deleteCollection(key).then(() => {
                    this.actions[key].mode = "view";
                    this.errorMessage = null;
                }).catch(err => {
                    this.actions[key].mode = "view";
                    this.errorMessage = err;
                });
            },
            createCollection: function () {
                this.createMode = "creating";
                const name = this.createName;

                this.store.createCollection(name).then(() => {
                    this.createName = "";
                    this.createMode = "view";
                    this.createErrorMessage = null;
                }).catch(err => {
                    this.createMode = "view";
                    this.createErrorMessage = err;
                });                
            }
        }
    }
</script>

<style scoped>

.NavigationCollections {
    overflow: hidden;
    padding: 12px;
}

h3 {
    margin-bottom: 8px;
}

.box {
    padding: 16px;
    background: var(--bg);
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
}

.box:not(:first-child) {
    margin-top: 16px;
}

.collection {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    min-height: 40px;
}

.collection .content {
    flex: 1 0 50%;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 0.75rem;
}

.collection .content:not(:first-child) {
    padding-top: 8px;
}

.collection .content:not(:first-child) {
    padding-bottom: 8px;
}

.collection .action {
    flex: 0 0 32px;
    cursor: pointer;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    border-radius: 4px;
    padding: 8px;
}

.collection .action img {
    max-width: 20px;
    max-height: 20px;
}

.collection .action:hover {
    text-decoration: underline;
    background: rgba(0, 0, 0, 0.05);
}

.collection input[type="text"] {
    border-radius: 4px;
    width: 100%;
}

.collection input[type="checkbox"] {
    margin-right: 8px;
}

.errorMessage {
    margin-top: 8px;
}

.create .content input {
    border-radius: 4px;
    width: 100%;
}

.create .action {
    display: flex;
    margin-top: 8px;
}

.create .action button {
    margin-right: 16px;
}

.createErrorMessage {
    margin-top: 8px;
}

</style>
