<template>
    <div class="SearchControl">
        <input type="text" placeholder="Search..." v-model="searchText">
        <div class="results" v-show="searchText">
            <div class="header">
                <h1 v-show="resultRoutes.length > 0">Results for search "{{ searchText }}"</h1>
                <h1 v-show="resultRoutes.length == 0">No results for search "{{ searchText }}"</h1>
            </div>
            <route-deck v-on:navigation="resetSearch" :routes="limitedResultRoutes"></route-deck>
            <div class="limit" v-if="limitedResultRoutes.length < resultRoutes.length">
                Showing the first {{ limitedResultRoutes.length }} out of {{ resultRoutes.length }} results.
                <button v-on:click="increaseLimit">Show more</button>
            </div>
        </div>
    </div>
</template>

<script>
    import RouteDeck  from './RouteDeck.vue';

    export default {
        components: { RouteDeck },
        name: "SearchControl",
        inject: ["router", "routes"],
        data: function () {
            return {
                searchText: "",
                limit: 10
            }
        },
        watch: {
            searchText: function () {
                this.limit = 10;
            },
            activePath: function () {
                this.resetSearch();
            }
        },
        methods: {
            increaseLimit: function () {
                this.limit += 10;
            },
            resetSearch: function () {
                this.searchText = "";
            }
        },
        computed: {
            activePath: function () {
                return this.router.state.activePath;
            },
            limitedResultRoutes: function () {
                const lrr = [];

                for (let i = 0; i < this.limit && i < this.resultRoutes.length; i++) {
                    lrr.push(this.resultRoutes[i]);
                }

                return lrr;
            },
            resultRoutes: function () {
                if (!this.searchText) return {};

                let sr = []; // Search results

                const segments = this.searchText.trim().split(/\s+/); // Split phrase using whitespace as a delimiter

                for (let i = 0; i < this.routes.length; i++) {
                    const route = this.routes[i];
                    let match = 0;

                    segments.forEach(ss => {
                        if (route.name?.toLowerCase().includes(ss) || route.desc?.toLowerCase().includes(ss)) {
                            match++;
                        }    
                    });

                    if (match == segments.length) {
                        sr.push(route);
                    }
                }

                return sr;
            }
        }
    }


</script>

<style scoped>

.SearchControl {
    height: 25px;
    width: 250px;
}

.results {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    background: var(--emptiness);
    width: 100%;
    min-height: 100%;
    margin-left: -1px;
    border-left: 1px solid var(--strongSeparator);
}

.header {
    padding: 24px 24px 0 24px;
    margin-top: 36px;
}

.limit {
    padding: 0 24px 24px 24px;
    font-size: 0.8rem;
}

.limit button {
    margin-left: 8px;
}

.SearchControl > input {
    position: relative;
    z-index: 51;
    width: 100%;
    border-radius: 16px;
    padding: 4px 8px 4px 8px;
}

</style>
