<template>
    <div class="UploaderTables">
        <loading-box v-if="tables === null">Loading...</loading-box>
        <div v-else class="tablesContainer">
            <div class="table" v-for="table in tables" :key="table">
                <h2>{{ table }}</h2>
                <button v-on:click="deleteTable(table)">Delete</button>
            </div>
        </div>
    </div>
</template>

<script>
    import LoadingBox from '../../components/LoadingBox.vue';

    export default {
        components: { LoadingBox },
        name: "UploaderTables",
        inject: ["router", "store"],
        props: {
            route: Object
        },
        data: function () {
            return {
                tables: null,
                apiBaseUrl: null,
            }
        },
        created: async function () {
            if (!this.route.payload?.apiBaseUrl) {
                console.error(`Cannot start file uploader. The attribute "apiBaseUrl" was not set in the payload for "${this.route.name}".`);
                return;
            }
            this.apiBaseUrl = this.route.payload.apiBaseUrl;

            await this.loadTables();
        },
        methods: {
            loadTables: async function () {
                const res = await fetch(`${ this.apiBaseUrl }/targetTables`, {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${ await this.store.refreshAndGetAuthToken() }`
                    }
                });
                const resBody = await res.json();

                this.tables = [...new Set(resBody.tables.map(t => t.name))];
            },
            deleteTable: async function (tableName) {
                const confirmation = confirm(`Are you sure you want to delete the table ${ tableName }?`);
                if (!confirmation) return;

                const res = await fetch(`${ this.apiBaseUrl }/deleteTable`, {
                    method: "POST",
                    headers: {
                        "Authorization": `Bearer ${ await this.store.refreshAndGetAuthToken() }`
                    },
                    body: JSON.stringify({
                        tableName
                    })
                });

                if (res.ok) {
                    this.tables = this.tables.filter(t => t !== tableName);
                } else {
                    alert("Couldn't delete the table.");
                }
            }
        }
    }
</script>

<style scoped>
    .UploaderTables {
        padding: 24px;
        font-size: 0.7rem;
    }

    .table {
        background: var(--bg);
        border-radius: 4px;
        padding: 16px;
        overflow: hidden;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
    }

    .table:not(:first-child) {
        margin-top: 24px;
    }

    .table button {
        margin-left: auto;
    }
</style>