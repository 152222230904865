<template>
    <div class="LoadingBox">
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="bubble"></div>
        <div class="message">
            <slot></slot>
        </div>
    </div>    
</template>

<script>
    export default {
    }
</script>

<style scoped>

.LoadingBox {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
}

.bubble {
    flex: 0 0 8px;
    display: inline-block;
    background: var(--accent);
    margin-right: 2px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
}

.bubble:nth-child(1) {
    animation: 1s ease-in-out infinite loading1;
}

.bubble:nth-child(2) {
    animation: 1s ease-in-out infinite loading2;
}

.bubble:nth-child(3) {
    animation: 1s ease-in-out infinite loading3;
}


.message {
    flex: 1 0 auto;
    margin-left: 6px;
}

@keyframes loading1 {
    0% {
        opacity: 1;
    }

    30% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes loading2 {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes loading3 {
    0% {
        opacity: 1;
    }

    70% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

</style>