<template>
    <div class="PageMenu">
        <div class="pane" v-if="activeButton == 'collections'">
            <navigation-collections v-if="activeButton == 'collections'"></navigation-collections>
        </div>

        <div class="pane" v-if="activeButton == 'guide' && guideLoaded">
            <page-guide></page-guide>
        </div>

        <div class="pane" v-if="activeButton == 'filters' && filtersLoaded">
            <page-filters></page-filters>
        </div>

        <div class="pane" v-if="activeButton == 'form'">
            <page-form></page-form>
        </div>

        <div class="buttons">
            <div class="button" v-on:click="toggleExpandedView();">
                <img v-if="!expandedView" class="colorInvertable" src="../assets/expand.png">
                <img v-if="expandedView" class="colorInvertable" src="../assets/contract.png">
            </div>

            <div class="button" v-if="collectionsLoaded" v-on:click="toggle('collections')" :class="{ active: activeButton == 'collections' }">
                <img class="colorInvertable" src="../assets/saved.png">
            </div>

            <div class="button" v-if="guideLoaded" v-on:click="toggle('guide')" :class="{ active: activeButton == 'guide' }">
                <img class="colorInvertable" src="../assets/guide.png">
            </div>

            <div class="button filters" v-if="filtersLoaded" v-on:click="toggle('filters')" :class="{ active: activeButton == 'filters' }">
                <img class="colorInvertable" src="../assets/filters.png">
                <div class="count">4</div>
            </div>

            <div class="button" v-if="formLoaded" v-on:click="toggle('form')" :class="{ active: activeButton == 'form' }">
                <img class="colorInvertable" src="../assets/guide.png">
            </div>

        </div>
    </div>    
</template>

<script>
    import NavigationCollections from './NavigationCollections.vue';
    import PageGuide from './PageGuide.vue';
    import PageFilters from './PageFilters.vue';
    import PageForm from './PageForm.vue';

    export default {
        components: { NavigationCollections, PageGuide, PageFilters, PageForm },
        inject: [ "router", "routes", "store" ],
        data: function () {
            return {
                activeButton: null
            }
        },
        methods: {
            toggle: function (button) {
                if (button == this.activeButton) {
                    this.activeButton = null;
                    return;
                }

                this.activeButton = button;
            },
            toggleExpandedView: function () {
                this.store.setExpandedView(!this.expandedView);
            }
        },
        computed: {
            activeRoute: function () {
                return this.router.state.activeRoute;
            },
            expandedView: function () {
                return this.store.state.settings.expandedView;
            },
            collectionsLoaded: function () {
                return !!this.store.state.collections;
            },
            guideLoaded: function () {
                return !!this.router.state.activeRoute.guide;
            },
            filtersLoaded: function () {
                return !!this.router.state.activeRoute.filters;
            },
            formLoaded: function () {
                return !!this.router.state.activeRoute.formId;
            }
        }
    }
</script>

<style scoped>

    .PageMenu {
        font-size: 0.75rem;
        height: 100%;
        background: var(--emptiness);
        position: relative;
        padding-top: 12px;
        padding-right: 12px;
        display: flex;
    }

    .buttons {
        display: flex;
        flex-flow: column nowrap;
    }

    .button {
        user-select: none;
        display: flex;
        height: 48px;
        width: 48px;
        padding-left: 12px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 0 8px 8px 0;
    }

    .button img {
        max-width: 24px;
        max-height: 24px;
    }

    .button:hover {
    }

    .button.active {
        background: var(--separator);
    }

    .button.filters {
        position: relative;
        text-align: right;
    }

    .button.filters .count {
        right: 2px;
        bottom: 2px;
        position: absolute;
        color: white;
        background: var(--accent);
        border-radius: 8px;
        font-size: 11px;
        min-width: 16px; 
        text-align: center;
        padding: 1px 3px 1px 3px;
    }

    .pane {
        background: var(--translucentEmptiness);
        border-left: 1px solid var(--separator);
        backdrop-filter: blur(3px);
        position: absolute;
        top: 0;
        left: -341px;
        height: 100%;
        width: 340px;
    }

</style>