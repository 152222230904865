<template>
    <div class="Logo" :class="{ animated: animated }">
       <div class="drawing">
           <div></div>
           <div></div>
           <div></div>
           <div></div>
           <div></div>
        </div>
        <div class="label" v-if="label">{{ label }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "DistributorLogo",
        props: {
            animated: Boolean,
            label: String
        }
    }
</script>

<style scoped>
    .Logo {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .label {
        flex: 0 0 auto;
        font-size: 0.8rem;
        margin-top: 32px;
        color: var(--subtleTextShade);
    }

</style>
