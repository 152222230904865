import { reactive } from "vue";

let routes = null;

export default {

    install: (app, installationRoutes) => {

        const orderRoutes = function (rs) {
            const cf = (a, b) => {               
                if (a === undefined && b === undefined) return 0;
                if (a === undefined && b < 0) return -1;
                if (a < 0 && b === undefined) return 1;
                if (a === undefined && b > 0) return 1;
                if (a > 0 && b === undefined) return -1;
                if (a < 0) a = rs.length + a;
                if (b < 0) b = rs.length + b;
                if (b > a) return -1;
                if (b < a) return 1;
                return 0;
            };

            rs.sort((ra, rb) => {
                let a = ra.order;
                let b = rb.order;

                return cf(a, b);
            });

            return rs;
        }

        routes = orderRoutes(installationRoutes);

        const getChildRoutes = function (parentPath) {
            const cr = []; 

            if (parentPath.slice(-1) == "/") {
                parentPath = parentPath.slice(0, -1);
            }

            routes.forEach(route => {
                const path = route.path;
                const match = path.match("^" + parentPath + "/[^/]+$");
                if (match) cr.push(route);
            });
            
            const ocr = orderRoutes(cr);

            return ocr;
        }

        const matchRoute = function (path) {
            for (const route of routes) {
                if (route.path === path) {
                    return route;
                }
            }

        }

        const getAnchorPath = function (path) {
            let trimmedPath = path;
            if (trimmedPath.slice(-1) == "/") trimmedPath = trimmedPath.slice(0, -1);

            const sh = trimmedPath.split("/");
                
            for (let i = 0; i < sh.length; i++) {
                const rs = sh.slice(0, i).join("/");
                const route = matchRoute(rs);

                if (route && route.type == "topic") {
                    return rs;
                }
            }

            return path;        
        }

        const refreshState = function () {
            let activePath = location.hash.substring(1);
            let activeRoute = matchRoute(activePath);
            
            if (!activeRoute && activePath.slice(-1) == "/") {
                location.hash = activePath.slice(0, -1);
                return;
            }

            if (!activeRoute) {
                if (matchRoute("/")) location.hash = "/";
                console.log("Cannot find \"" + activePath + "\". Redirecting...");
                return;
            }

            if (activeRoute.type == "topic") {
                const cps = getChildRoutes(activePath);
                location.hash = cps[0].path;
                return;
            }

            state.activePath = activePath;
            state.activeRoute = activeRoute;

            const anchorPath = getAnchorPath(activePath);

            state.anchorPath = anchorPath;
            state.anchorRoute = matchRoute(anchorPath);
        }

        /*
        State is reactive, allowing components such as App to react to
        changes in path or route.
        */ 

        let state = reactive({
            activePath: null,
            activeRoute: null,
            anchorPath: null,
            anchorRoute: null,
            version: 1
        });

        /*
        Methods aren't reactive, because they aren't expected to change.
        */

        const updateVersion = function () {
            state.version++;
        }
        
        let methods = {
            matchRoute,
            getChildRoutes,
            updateVersion,
            refreshState,
            getAnchorPath
        };

        /*
        Pack reactive state and methods into router object
        */ 

        let router = {
            state, methods
        }

        window.addEventListener("hashchange", () => {
            router.methods.refreshState();
        }, false);

        router.methods.refreshState();

        app.provide("router", router);
        app.provide("routes", routes);
    }

}