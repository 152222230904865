<template>
    <div class="FolderPage">
        <route-deck v-if="folderRoutes.length > 0" :routes="folderRoutes"></route-deck>
        <div class="empty" v-if="folderRoutes.length == 0">
            This folder is empty or you don't have the required permissions to view its contents.
            <span v-if="!isAuthenticated">If you have an account, try logging in.</span> 
        </div>
    </div>
</template>

<script>
    import RouteDeck from '../components/RouteDeck.vue';

    export default {
        components: { RouteDeck },
        name: "FolderPage",
        inject: ["routes", "router", "store" ],
        props: [ "path" ],
        computed: {
            folderRoutes: function () {
                return this.router.methods.getChildRoutes(this.path);
            },
            isAuthenticated: function () {
                return !!this.store.state.userData;
            }
        }
    }
</script>

<style scoped>

    .empty {
        padding: 24px;
        font-size: 0.8rem;
    }

</style>