<template>
    <div class="PageForm">
        <div class="box">
            <form-widget :formId="formId"></form-widget>
        </div>
    </div>
</template>

<script>
    import FormWidget from '../components/FormWidget.vue'

    export default {
        name: "PageForm",
        components: { FormWidget },
        inject: ["router", "store"],
        computed: {
            formId: function () {
                return this.router.state.activeRoute.formId;
            }
        }
    }
</script>

<style scoped>

    .PageForm {
        overflow: hidden;
        padding: 12px;
    }

    h3 {
        margin-bottom: 8px;
    }

    .box {
        background: var(--bg);
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    }

</style>
