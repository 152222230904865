<template>
    <div class="FormControlAB">

        <template v-if="activeComparison">
            <div class="message"> </div>
            <div class="optionComparison">
                <div class="optionAB" v-on:click="addComparisonResult(activeComparison.a, activeComparison.b, 'lt')">{{ activeComparison.a.desc || activeComparison.a.value }}</div>
                <div class="optionAB" v-on:click="addComparisonResult(activeComparison.a, activeComparison.b, 'gt')">{{ activeComparison.b.desc || activeComparison.b.value }}</div>
                <div class="optionEqual" v-on:click="addComparisonResult(activeComparison.a, activeComparison.b, 'eq')">Equal</div>
            </div>
        </template>

        <template v-if="!activeComparison">
            <div class="message"><p>The items below have been arranged based on your previous responses.</p><p>You may rearrange them by dragging and dropping them.</p></div>
            <div class="optionOrder">
                <div class="optionWrapper" v-for="(option, index) in values" :key="index">
                    <div class="optionPosition">{{ (index + 1) }}</div>
                    <div class="option"
                        :class="{ 'dragging': activeDragIndex == index }"
                        draggable="true"
                        v-on:dragstart="dragStart($event)"
                        v-on:dragend="dragEnd($event)"
                        :data-index="index"
                        v-on:dragenter.prevent
                        v-on:dragleave.prevent
                        v-on:dragover="dragOver($event)"
                        v-on:drop="drop($event)">
                        {{ option.desc || option.value }}
                    </div>

                </div>
            </div>
        </template>
    </div>
</template>

<script>

export default {
    name: "FormControlAB",
    emits: [ "change" ],
    data: function () {
        return {
            values: null,
            activeDragIndex: null,
            activeComparison: null,
            comparisonResults: [  ]
        }
    },
    props: {
        options: Array
    },
    watch: {
        "options": {
            handler: function (pre, post) {
                if (JSON.stringify(pre) === JSON.stringify(post)) return;

                this.comparisonResults = [];
                this.activeComparison = null;
                this.values = this.mergeSort(this.options);
                this.emitChangeEvent();
            },
            immediate: true
        }
    },
    methods: {
        fetchComparisonResult: function (a, b) {
            for (let i = 0; i < this.comparisonResults.length; i++) {
                const comp = this.comparisonResults[i];
                if (comp.a.value == a.value && comp.b.value == b.value) return comp.result;
            }

            this.activeComparison = { a, b };

            return null;
        },
        merge: function (arr1, arr2) {
            if (arr1 === null || arr2 === null) return null;
            
            const sorted = [];

            while (arr1.length && arr2.length) {
                const result = this.fetchComparisonResult(arr1[0], arr2[0]);

                if (result === null) return null;

                if (result == 'lt') {
                    sorted.push(arr1.shift());
                } else {
                    sorted.push(arr2.shift());
                } 
            }

            const merged = sorted.concat(arr1.slice().concat(arr2.slice()));

            return merged;
        },
        mergeSort: function (arr) {

            /*
            A merge sort is attempted every time more comparison results become available.
            If the algorithm needs a comparison that hasn't been made, the sort is aborted and the comparison needed becomes the active comparison.
            The process is repeated until the sort algorithm has all the comparisons needed.
            */

            if (arr.length <= 1) return arr;
            
            const mid = Math.floor(arr.length / 2);
            const left = this.mergeSort(arr.slice(0, mid));
            const right = this.mergeSort(arr.slice(mid));

            return this.merge(left, right);
        },
        addComparisonResult: function (a, b, result) {
            const cr = { a, b, result };

            this.comparisonResults.push(cr);

            // With one more comparison result available, attempt to sort again

            this.values = this.mergeSort(this.options);

            if (this.values !== null) {
                this.emitChangeEvent();
                this.activeComparison = null;
            }
        },
        emitChangeEvent: function () {
            let value = null;
            
            if (this.values) {
                value = this.values.map(e => e.value);
            }
            
            this.$emit("change", { value });
        },
        dragStart: function (ev) {
            //ev.dataTransfer.setData("text/plain", ev.target.innerText);
                       
            this.activeDragIndex = parseInt(ev.target.getAttribute("data-index"));

            ev.dataTransfer.effectAllowed = "move";
        },
        dragEnd: function (ev) {
            ev.preventDefault();
            this.activeDragIndex = null;
        },
        dragOver: function (ev) {
            ev.preventDefault();

            const ti = parseInt(ev.target.getAttribute("data-index"));

            if (this.activeDragIndex === ti) return;

            const nv = this.values.slice();
               
            nv.splice(this.activeDragIndex, 1);
            nv.splice(ti, 0, this.values[this.activeDragIndex]);
            this.activeDragIndex = ti;

            this.values = nv;
            
            this.emitChangeEvent();                        
        },
        drop: function (ev) {
            ev.dataTransfer.dropEffect = "move";
        } 
    }
}
</script>

<style scoped>
.FormControlAB {
}

.message {
    font-size: 0.7rem;
    text-align: right;
    margin-bottom: 16px;
    margin-top: 8px;
}

.optionComparison {
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    display: grid;
}

.optionComparison div {
    background: var(--subtleSeparator);
    border-radius: 4px;
    padding: 16px;
    text-align: center;
    cursor: pointer;
}

.optionComparison .optionAB {
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.optionComparison .optionEqual {
    grid-column-start: 1;
    grid-column-end: 3;
}

.optionOrder .option {
    background: var(--subtleSeparator);
    padding: 16px;
    border-radius: 4px;
    cursor: grab;
    transition: 0.2s linear box-shadow;
    flex: 1 0 auto;
}



.optionOrder .option.dragging {
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.4);
}

.optionOrder .optionWrapper {
    display: flex;
}

.optionOrder .optionWrapper:not(:first-child) {
    margin-top: 8px;
}

.optionOrder .optionWrapper:not(:last-child) {
    margin-bottom: 8px;
}

.optionOrder .optionPosition {
    flex: 0 0 50px;
    padding: 16px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>
