<template>
    <div class="CollectionsPage">
        <div class="presenter" v-if="presenterCollection">
            <collection-presenter :collection="presenterCollection" :initial-path="presenterPath" v-on:presenter-close="closePresenter"></collection-presenter>
        </div>
        <div class="empty" v-if="!collections || Object.keys(collections).length == 0">
            You don't have any collections.
        </div>
        <div class="deck" v-if="!presenterCollection">
            <div class="card" v-for="(collection, key) in collections" :key="key">
                <div class="head">
                    <div class="actionContainer">
                        <template v-if="actions[key].mode == 'view'">
                            <div class="action" v-on:click="openPresenter(collection)"><img class="colorInvertable" src="../assets/present.png" /></div>
                            <div class="action" v-on:click="actions[key].mode = 'edit'; actions[key].editedName = collection.name;"><img class="colorInvertable" src="../assets/edit.png" /></div>
                            <div class="action" v-on:click="actions[key].mode = 'delete'"><img class="colorInvertable" src="../assets/delete.png" /></div>
                        </template>
                        <template v-if="actions[key].mode == 'edit'">
                            <div class="action" v-on:click="editCollection(key)">Confirm</div>
                            <div class="action" v-on:click="actions[key].mode = 'view'">Cancel</div>
                        </template>
                        <template v-if="actions[key].mode == 'delete'">
                            <div class="action" v-on:click="deleteCollection(key)">Confirm deletion</div>
                            <div class="action" v-on:click="actions[key].mode = 'view'">Cancel</div>
                        </template>
                        <template v-if="actions[key].mode == 'deleting'">
                            <loading-box>Deleting...</loading-box>
                        </template>
                        <template v-if="actions[key].mode == 'updating'">
                            <loading-box>Updating...</loading-box>
                        </template>
                    </div>
                    <h2 v-if="actions[key].mode != 'edit'">{{ collection.name }}</h2>
                    <input v-if="actions[key].mode == 'edit'" type="text" v-model="actions[key].editedName">
                    <div class="errorMessage" v-if="actions[key].errorMessage">
                        <message-box type="error">{{ actions[key].errorMessage }}</message-box>
                    </div>
                </div>
                <div class="images" v-if="imagePaths[key].length">
                    <div class="image" v-for="(path, index) in imagePaths[key]" :key="index" :style="{ zIndex: (11-index), '--imgi': index, '--imgc': imagePaths[key].length }">
                        <route-image :route="router.methods.matchRoute(path)"></route-image>
                    </div>
                </div>
                <div class="images empty" v-if="!imagePaths[key].length">
                    This collection is empty.         
                </div>
                <div class="content">
                    <div class="link" v-for="path in collection.paths" :key="path">
                        <a :href="'#' + path">
                            {{ router.methods.matchRoute(path).name }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CollectionPresenter from '../components/CollectionPresenter.vue';
    import LoadingBox from '../components/LoadingBox.vue';
    import MessageBox from '../components/MessageBox.vue';
    import RouteImage from '../components/RouteImage.vue';

    export default {
        name: "CollectionsPage",
        components: { RouteImage, CollectionPresenter, LoadingBox, MessageBox },
        inject: ["store", "routes", "router"],
        data: function () {
            return {
                presenterCollection: null,
                presenterPath: null,
                imagePaths: [],
                actions: {}
            }
        },
        methods: {
            openPresenter: function (collection, path = null) {

                /*
                The presenter can be opened with null path (presenter will display "Select a page")
                or with a non-null path which will be open with the presenter is shown.
                */

                this.presenterCollection = collection;
                this.presenterPath = path;
            },
            closePresenter: function () {
                this.presenterCollection = null;
                this.presenterPath = null;
            },
            editCollection: function (key) {

                // Performs the edit once confirmed

                const editedName = this.actions[key].editedName;
                this.actions[key].mode = "updating";

                this.store.modifyCollection(key, editedName).then(() => {
                    this.actions[key].mode = "view";
                    this.actions[key].errorMessage = null;
                }).catch(err => {
                    this.actions[key].mode = "view";
                    this.actions[key].errorMessage = err;
                });
            },
            deleteCollection: function (key) {

                // Performs the delete once confirmed
                
                this.actions[key].mode = "deleting";

                this.store.deleteCollection(key).then(() => {
                    this.actions[key].mode = "view";
                    this.actions[key].errorMessage = null;
                }).catch(err => {
                    this.actions[key].mode = "view";
                    this.actions[key].errorMessage = err;
                });
            },
            shuffle: function (arr) {

                // Fischer-Yates shuffling algorithm, used to show different images every time 

                for (let i = arr.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [arr[i], arr[j]] = [arr[j], arr[i]];
                }
            }
        },
        computed: {
            collections: function () {
                const c = this.store.state.collections;
                
                // Match routes, filter invalid routes

                return c;
            }
        },
        watch: {
            "store.state.collections": {
                handler: function () {
                    if (!this.collections) return;

                    Object.keys(this.collections).forEach(key => {
                        this.actions[key] = {};
                        this.actions[key].mode = "view";
                        
                        let csp = [...this.collections[key].paths]; 
                        this.shuffle(csp);
                        this.imagePaths[key] = csp.slice(0, 4);
                    });
                },
                immediate: true
            }
        }
    }
</script>

<style scoped>
    .CollectionsPage {
        width: 100%;
        height: 100%;
    }

    .presenter {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 200;
        width: 100vw;
        height: 100vh;
    }

    .empty {
        font-size: 0.8rem;
        padding: 24px;
    }

    .deck {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: flex-start;
        align-content: flex-start;
        flex-flow: row wrap;
        padding-bottom: 16px;
    }

    .card {
        margin-left: 16px;
        margin-top: 16px;
        margin-right: 16px;
        flex: 0 0 270px;
        border-radius: 4px;
        overflow: hidden;
        background: var(--bg);
        height: min-content;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
        display: flex;
        flex-flow: column;
    }

    .card .head {
        flex: 1 0 auto;
        padding: 16px;
    }

    .card .head .actionContainer {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 8px;
        min-height: 40px;
    }

    .card .head input {
        width: 100%;
        font-size: 1.2rem;
    }

    .card .head .content {
        flex: 0 0 auto;
    }

    .card .head .action {
        flex: 0 0 auto;
        min-width: 32px;
        cursor: pointer;
        margin-left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.75rem;
        border-radius: 4px;
        padding: 8px;
    }

    .card .head .action:hover {
        text-decoration: underline;
        background: var(--subtleSeparator);
    }

    .card .head .action img {
        max-width: 20px;
        max-height: 20px;
    }

    .card .head .errorMessage {
        margin-top: 12px;
    }

    .card .images {
        background: var(--subtleSeparator);
        flex: 1 0 auto;
        height: 214px;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        font-size: 0.75rem;
    }

    .card .images.empty {
        padding: 16px;
    }

    /*
    --imgi: Image index
    --imgc: Image count 
    */

    .card .images .image {
        position: absolute;
        flex: 0 0 150px;
        height: 150px;
        width: 150px;
        font-size: 42px;
        overflow: hidden;
        border-radius: 4px;
        background: var(--bg);
        color: rgba(0, 0, 0, 0.1);

        /*
        16px * var(--imgi) calculates displacement per image
        (100% - 150px - (var(--imgc) - 1) * 16px) / 2 centers the group
        */

        left: calc(16px * var(--imgi) + (100% - 150px - (var(--imgc) - 1) * 16px) / 2);
        top: calc(-16px * var(--imgi) + (214px - 150px + (var(--imgc) - 1) * 16px) / 2);
    }

    .card .content {
        font-size: 0.75rem;
        padding: 16px;
    }

    .card .content .link:not(:first-child) {
        margin-top: 16px;
    }

    /* If the viewport cannot accomodate 2 cards horizontally, use a single card layout. */

    @media (max-width: 604px) { 
        .card {
            flex: 0 0 calc(100vw - 32px);
        }
        .card > .image {
            height: calc(100vw - 32px);
        }
    }

</style>