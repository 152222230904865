<template>
    <div class="RouteImage">
        <div class="image" :class="{ 'icon': isIcon }" v-if="imageUrl" :style="{ backgroundImage: 'url(' + imageUrl + ')' }">
        </div>
        <div class="image" v-if="!imageUrl">
            {{ getInitials(route) }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "RouteImage",
        props: {
            route: Object,
            preferIcon: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            getInitials: function (route) {
                return  route.initials || 
                        route.name?.split(" ").reduce((ia,  i) => ia + i.substring(0, 1), "");
            }
        },
        computed: {
            imageUrl: function () {
                if (this.preferIcon && this.route.icon) return this.route.icon;

                return this.route.image;
            },
            isIcon: function () {
                if (this.preferIcon && this.route.icon) return true;
                return false;
            }
        }
    }
</script>

<style scoped>
    .RouteImage {
        width: 100%;
        height: 100%;
    }

    .image {
        width: 100%;
        height: 100%;
        text-transform: uppercase;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .image.icon {
        background-size: 75%;
    }
</style>
