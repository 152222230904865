import { createApp } from 'vue'
import App from './App.vue'

import './global.css'
import PortalRouter from './PortalRouter.js';

import store from './Store.js';

const app = createApp(App);

app.provide("store", store);

store.init().then(() => {
    document.title = store.state.config.portalName || "Vizify Portal";
    app.use(PortalRouter, store.state.routes);
    app.mount("#app");
}).catch(err => {
    document.title = "Vizify Portal";
    
    if (document.getElementById("initMessage")) {
        document.getElementById("initMessage").textContent = `An error occurred: ${ err }`;
    }

    console.error(`Couldn't load the application. Error: ${ err }`);
});

