import { reactive } from "vue";
import { PublicClientApplication } from "@azure/msal-browser";

export default {
 
    state: reactive({
        settings: {
            activeTheme: "light",
            expandedView: false,
            collapsedNavigationMenu: true
        },
        authToken: null,
        userData: null,
        routes: null,
        collections: null,
        config: null
    }),

    init: function () {
        return new Promise ((resolve, reject) => {
            console.log("Loading config...");
            this.loadConfig().then(() => {
                console.log("Loading user data...");
                return this.loadUserData();  
            }).then(() => {
                console.log("Loading routes...");
                return this.loadRoutes();
            }).then(() => {
                if (this.state.userData !== null) {
                    console.log("Loading collections...");
                    return this.loadCollections();
                }

                return Promise.resolve();
            }).then(() => {
                resolve();
            }).catch(err => {
                reject(err);
            });
        });
    },

    // Settings

    setActiveTheme: function (s) {
        this.state.settings.activeTheme = s;
    },

    setExpandedView: function (b) {
        if (b) {
            this.setCollapsedNavigationMenu(true);
        }

        this.state.settings.expandedView = b;
    },

    setCollapsedNavigationMenu: function (b) {
        this.state.settings.collapsedNavigationMenu = b;
    },

    // User data (authentication)

    getRedirectURL: function () {
        const { msalRedirectURI } = this.state.config;
        const { host } = window.location;
        if (!msalRedirectURI ) {
            console.error("Configuration for MSAL must include redirect URI.");
            return;
        }

        if (host == "localhost:4280") {
            return "http://localhost:4280/"
        } else {
            return msalRedirectURI;
        }
    },

    getMsalConfig: function () {
        const { msalAuthority, msalClientId } = this.state.config;

        if (!msalAuthority || !msalClientId) {
            console.error("Configuration for MSAL must include authority and client id.");
            return;
        }

        const msalConfig = {
            auth: {
                clientId: msalClientId,
                authority: msalAuthority,
                redirectUri: this.getRedirectURL(),
                postLogoutRedirectUri: this.getRedirectURL(),
                navigateToLoginRequestUrl: false
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false
            }
            // ,system: {
            //     loggerOptions: {
            //         loggerCallback: (level, message, containsPii) => {
            //             if (containsPii) return;
            //             console.log(level, message);
            //         }
            //     }
            // }
        };

        return msalConfig;
    },

    refreshAuthToken: async function () {
        const { msalScope } = this.state.config;
        if (!msalScope ) {
            console.error("Configuration for MSAL must include scope.");
            return;
        }
        const msalConfig = this.getMsalConfig();
        const loginRequest = {
            scopes: [msalScope]
        };            
        const msalInstance = new PublicClientApplication(msalConfig);
        const accounts = msalInstance.getAllAccounts();

        if (accounts.length == 0 || accounts.length > 1) {
            this.state.authToken = await this.getAuthTokenPopup(msalInstance, loginRequest);
            return;
        }

        try {
            this.state.authToken = await this.getAuthTokenSilent(msalInstance, loginRequest, accounts[0]);
        } catch (err) {
            console.error(err);
            this.state.authToken = await this.getAuthTokenPopup(msalInstance, loginRequest);
        }
    },

    getAuthTokenSilent: async function (msalInstance, loginRequest, account) {
        const loginRequestWithAccount = {
            ...loginRequest,
            account
        };
        const res = await msalInstance.acquireTokenSilent(loginRequestWithAccount);
        return res.accessToken;
    },

    getAuthTokenPopup: async function (msalInstance, loginRequest) {
        const res = await msalInstance.acquireTokenPopup(loginRequest);
        return res.accessToken;
    },

    refreshAndGetAuthToken: async function () {
        await this.refreshAuthToken();
        return this.state.authToken;
    },

    login: async function () {
        await this.refreshAuthToken();
        await this.init();
    },

    logout: async function () {
        const msalConfig = this.getMsalConfig();
        const msalInstance = new PublicClientApplication(msalConfig);
        const res = await msalInstance.logoutPopup();
        this.state.authToken = res.accessToken;
        await this.init();
    },

    loadUserData: async function () {
        if (!this.state.authToken) {
            console.log("Authentication token not found. Continuing as guest.");
            this.state.userData = null;
            return;
        }

        console.log("Authentication token found. Getting user data...");
        await this.refreshAuthToken();

        try {
            const res = await fetch(`${ process.env.PORTAL_API_BASE_URL }/authInfo`, {
                headers: {
                    "Authorization": `Bearer ${ this.state.authToken }`
                }
            });
            const resBody = await res.json();
            if (!res.ok) {
                throw(`Cannot obtain user data. ${ resBody?.message }`);
            }
            this.state.userData = resBody;
        } catch (err) {
            this.state.userData = null;
            console.error(err);
        }
    },

    // Config

    loadConfig: function () {
        return new Promise ((resolve, reject) => {
            fetch(`${ process.env.PORTAL_API_BASE_URL }/config`).then(res =>
                Promise.all([res, res.json()])
            ).then(([res, data]) => {
                if (res.status > 299) {
                    reject(data.message);
                    return;
                }
    
                this.state.config = data;    
    
                resolve();
            }).catch(err => {
                console.log(err);
                reject("Couldn't connect to the server to load configuration.");
            });
        });
    },

    // Routes

    loadRoutes: async function () {
        let url = process.env.PORTAL_API_BASE_URL;
        if (this.state.userData !== null) {
            await this.refreshAuthToken();
            url += "/routes";
        } else {
            url += "/anonymousRoutes";
        }
        const res = await fetch(url, {
            headers: {
                "Authorization": `Bearer ${ this.state.authToken }`
            }
        });
        const resBody = await res.json();
        if (!res.ok) {
            throw(`Cannot load routes. ${ resBody?.message }`);
        }
        const routes = [];
        resBody.forEach(route => {
            try {
                this.validateRoute(route);
            } catch (err) {
                console.error(`Cannot load route. ${ err }`);
                return;
            }
            routes.push(route);
        });
        this.state.routes = routes;
    },

    validateRoute: function (route) {
        if (!route.name) throw `Route with id "${route.id}" doesn't have a name.`;
        if (!route.type) throw `Route "${route.name}" doesn't have a type.`;
        if (!route.path) throw `Route "${route.name}" doesn't have a path.`;
        if (route.payload && route.payload.error) throw `Route "${route.name}" has an invalid payload.`;
    },

    // Collections

    loadCollections: function () {
        return new Promise ((resolve, reject) => {
            this.refreshAuthToken().then(() => 
                fetch(`${ process.env.PORTAL_API_BASE_URL }/collections`, {
                    headers: {
                        "Authorization": `Bearer ${ this.state.authToken }`
                    }
                })
            ).then(res =>
                Promise.all([res, res.json()])
            ).then(([res, data]) => {
                if (res.status > 299) {
                    reject(data.message);
                    return;
                }
    
                this.state.collections = data;    
    
                resolve();
            }).catch(err => {
                console.log(err);
                reject("Couldn't connect to the server to load collections.");
            });
        });
    },

    modifyCollection: function (key, name) {

        // todo check if it exists
        // todo check if there's another one with the same name

        return this.createOrReplaceCollection(key, name);
    },

    deleteCollection: function (key) {
        return new Promise ((resolve, reject) => {
            this.refreshAuthToken().then(() =>          
                fetch(`${ process.env.PORTAL_API_BASE_URL }/collection`, {
                    headers: {
                        "Authorization": `Bearer ${ this.state.authToken }`
                    },
                    method: "delete",
                    body: JSON.stringify({ 
                        key
                    })
                })
            ).then(res =>
                Promise.all([res, res.json()])
            ).then(([res, data]) => {
                if (res.status > 299) {
                    reject(data.message);
                    return;
                }
    
                delete this.state.collections[key];  
    
                resolve();
            }).catch((err) => {
                console.log(err);
                reject("Couldn't connect to the server to delete collection.");
            });
        });
    },

    createOrReplaceCollection: function (key, name) {
        return new Promise ((resolve, reject) => {
            this.refreshAuthToken().then(() => 
                fetch(`${ process.env.PORTAL_API_BASE_URL }/collection`, {
                    headers: {
                        "Authorization": `Bearer ${ this.state.authToken }`
                    },
                    method: "put",
                    body: JSON.stringify({ 
                        key,
                        name
                    })
                })
            ).then(res =>
                Promise.all([res, res.json()])
            ).then(([res, data]) => {
                if (res.status > 299) return reject(data.message);   

                const paths = this.state.collections[key]?.paths || [];

                this.state.collections = {
                    ...this.state.collections, 
                    ...{ [key]: { name, paths }}
                };
    
                resolve(key);
            }).catch((err) => {
                console.log(err);
                reject("Couldn't connect to the server to create or replace collection.");
            });
        });
    },

    createCollection: function (name) {
        const cleanName = name.replace(/[\W_]+/g,"_"); // Alphanumeric only
        const randomSeq = Math.random().toString(36).substring(2);
        
        const key = cleanName + "-" + randomSeq;

        return this.createOrReplaceCollection(key, name);
    },

    addPath: function (key, path) {
        return new Promise ((resolve, reject) => {
            this.refreshAuthToken().then(() => 
                fetch(`${ process.env.PORTAL_API_BASE_URL }/collectionPath`, {
                    headers: {
                        "Authorization": `Bearer ${ this.state.authToken }`
                    },
                    method: "put",
                    body: JSON.stringify({ 
                        key, path
                    })
                })
            ).then(res =>
                Promise.all([res, res.json()])
            ).then(([res, data]) => {
                if (res.status > 299) return reject(data.message);   

                this.state.collections[key].paths.push(path);
    
                resolve(key);
            }).catch((err) => {
                console.log(err);
                reject("Couldn't connect to the server to create or replace collection.");
            });
        });
    },

    removePath: function (key, path) {
        return new Promise ((resolve, reject) => {
            this.refreshAuthToken().then(() => 
                fetch(`${ process.env.PORTAL_API_BASE_URL }/collectionPath`, {
                    headers: {
                        "Authorization": `Bearer ${ this.state.authToken }`
                    },
                    method: "delete",
                    body: JSON.stringify({ 
                        key, path
                    })
                })
            ).then(res =>
                Promise.all([res, res.json()])
            ).then(([res, data]) => {
                if (res.status > 299) return reject(data.message);
    
                const filterer = cur => cur != path;
                const filteredArr = this.state.collections[key].paths.filter(filterer);
        
                this.state.collections[key].paths = filteredArr;
    
                resolve();
            }).catch((err) => {
                console.log(err);
                reject("Couldn't connect to the server to remove collection path.");
            });
        });

    }

};