<template>
    <div class="MessageBox" :class="classObject">
        <slot></slot>
    </div>    
</template>

<script>
    export default {
        props: {
            type: String
        },
        computed: {
            classObject: function () {
                return {
                    error: this.type == "error",
                    info: this.type == "info",
                    success: this.type == "success",
                }
            }
        }
    }
</script>

<style scoped>

.MessageBox {
    font-size: 0.75rem;
    padding: 16px;
    border-radius: 4px;
    overflow: hidden;
}

.error {
    background: rgba(255, 0, 0, 0.1);
}

.success {
    background: rgba(0, 150, 0, 0.2);
}

.info {
    background: rgba(0, 0, 0, 0.05);
}


</style>