<template>
    <div class="RouteDeck">
        <div class="card" tabindex="1" v-for="(route, index) in routes" :key="index" v-on:click="navigate(route)" v-on:keypress="keypressCard(route, $event)">
            <div class="image">
                <route-image :route="route"></route-image>
            </div>
            <div class="main">
                <h2>{{ route.name }}</h2>
                <div class="desc">{{ route.desc }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import RouteImage from './RouteImage.vue';

    export default {
        components: { RouteImage },
        name: "RouteDeck",
        emits: ["navigation"],
        props: {
            routes: Object
        },
        methods: {
            navigate: function (route) {
                if (route.type == "link") {
                    window.open(route.payload.url);
                    return;
                }

                location.href = "#" + route.path;
                this.$emit("navigation");
            },
            keypressCard: function (route, event) {
                if (event.key != "Enter") return; 
                this.navigate(route);
            }
        }
    }
</script>

<style scoped>
    .RouteDeck {
        padding: 24px;
        display: grid;
        grid-gap: 24px;
        grid-template-columns: repeat(auto-fill, 200px);
    }

    .card {
        cursor: pointer;
        flex: 0 0 200px;
        border-radius: 4px;
        overflow: hidden;
        background: var(--bg);
        min-height: 324px;
        height: min-content;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
        outline: none;
    }

    .card:hover, :focus {
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.6);
    }

    .image {
        width: 100%;
        height: 200px;
        font-size: 56px;
        background: var(--bg);
        color: var(--separator);
    }

    .main {
        border-top: 1px solid var(--subtleSeparator);
        padding: 16px;
    }

    .desc {
        font-size: 0.75rem;
    }

    h2 {
        margin-bottom: 8px;
    }

    /* If the viewport cannot accomodate 2 cards horizontally, use a single card layout. */

    @media (max-width: 464px) { 
        .card {
            flex: 0 0 calc(100vw - 32px);
        }
        .card > .image {
            height: calc(100vw - 32px);
        }
    }
</style>