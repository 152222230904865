<template>
    <div class="TopicMenu">
        <a :href="'#' + topicRoute.path" v-for="(topicRoute, index) in topicRoutes" :key="index" :class="{ active: activePath == topicRoute.path }">
            {{ topicRoute.name }}
        </a>
    </div>    
</template>

<script>
    export default {
        inject: [ "router", "routes" ],
        computed: {
            activePath: function () {
                return this.router.state.activePath;
            },
            anchorPath: function () {
                return this.router.state.anchorPath;
            },
            topicRoutes: function () {
                return this.router.methods.getChildRoutes(this.anchorPath);
            }
        }
    }
</script>

<style scoped>

    .TopicMenu {
        padding: 12px;
        font-size: 0.75rem;
        display: flex;
        width: 100%;
        flex-flow: row wrap;
    }

    @media (min-width: 1400px) { 
        .TopicMenu {
            flex-flow: column nowrap;
        }
    }

    a {
        display: block;
        border: 1px solid transparent;
        padding: 12px;
    }

    a:focus {
        border-color: var(--separator);
        border-radius: 4px;
        text-decoration: none;
    }

    a.active {
        color: var(--selectedText);
        background: var(--selected);
    }


</style>